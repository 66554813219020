<!--
목적 : 공종팝업 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customProcess"
      class="customProcess"
      :dense="dense"
      stack-label
      filled
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <q-chip
          v-for="(item, index) in setValueTextToArray(valueText)"
          :key="index"
          color="orange-6"
          text-color="white"
          class="q-ma-none customchipdept">
          {{item}}
        </q-chip>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "c-process-mst-multi",
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '공종',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'process', // upprocess 상위공종
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: "multiple",
    },
    constructTypeCd: {
      type: String,
      default: "",
    },
    parentcheckProcess: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      valueTextArray: [],
      processList: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    constructTypeCd: {
      handler: function () {
        this.setValueText();
      },
      deep: false,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customProcess'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customProcess'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      let _list = [];
      this.$http.url = selectConfig.mdm.process.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        constructTypeCd: this.constructTypeCd,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.processList = _result.data;
        this.$_.forEach(_result.data, (_item) => {
          if (this.vValue !== null) {
            if (this.vValue.indexOf(_item.processCd) > -1) {
              _list.push(_item.processName);
            }
          }
        });

        this.valueText = _list.join();
      },
      () => {
      });
    },
    setValueTextToArray(_str) {
      if (_str !== '') {
        return _str.split(',');
      } else {
        return null;
      }
    },
    search() {
      this.popupOptions.title = "공종 조회";
      this.popupOptions.param = {
        constructTypeCd: this.constructTypeCd,
        parentcheckProcess: this.parentcheckProcess,
        processList: this.processList,
        multiple: this.multiple,
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/common/process/processMstMultiPop.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = "30%";
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        var pName = [];
        var pCd = [];
        this.$_.forEach(data, (item) => {
          if (item !== undefined) {
            pName.push(item.processName);
            pCd.push(item.processCd);
          }
        });
        this.valueText = pName.join(",");
        this.$emit("processName", pName.join(","));
        this.$emit("input", pCd.join(","));
      }
    },
    reset() {
      this.valueText = "";
      this.$emit("input", null);
      this.$emit("change", null);
    },
  },
};
</script>
<style lang="sass">
.customProcess
  .q-field__control-container
    padding-bottom: 2px !important
  padding-bottom: 14px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.9em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 0px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customProcess:hover
  .dateCloseIcon
    display: block
.customProcess.q-field--dense .q-field__control, .customProcess.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 31px !important
  height: min-content !important
.customProcess.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.customchipdept.q-chip
  margin-top: -3px !important
  margin-right: 3px !important
  .q-chip__content
    font-size: 0.95em !important
</style>