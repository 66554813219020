<template>
  <div class="header-dots">
    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl" variant="link" no-caret right>
      <span slot="button-content">
          <q-btn
            flat
            dense
            round
            color="white"
            class="topnoti"
            icon="notifications">
            <q-badge color="red" rounded floating v-show="isBadge" :class="[isBadge,'animatedBadge']" />
          </q-btn>
      </span>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner dropdown-menu-header-inner2 bg-orange-7">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-white">
            <h5 class="menu-header-title">알림</h5>
          </div>
        </div>
      </div>
      <div class="nav-justified">
        <b-tabs class="card-header-tab-animation" card>
          <b-tab title="나의 결재" class="p-3" active>
            <div class="scroll-gradient">
              <div class="scroll-area-sm">
                <VuePerfectScrollbar class="scrollbar-container">
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-success"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title timeline-title2" style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')">결재할 문서 </h4>
                          <p> 결재
                            <q-badge rounded color="success" :label="apprCnt1" />
                          </p>
                          <p> 합의
                            <q-badge rounded color="success" :label="apprCnt2" />
                          </p>
                          <p> 확인
                            <q-badge rounded color="success" :label="apprCnt3" />
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title timeline-title2" style="cursor:pointer" @click="goPage('/sys/appr/doc/apprPass')">회람함 (최근 1주일)
                            <q-badge rounded color="warning" :label="apprCnt4" />
                          </h4>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab>
          <!-- <b-tab title="업무 알림" class="p-3">
            <div class="scroll-gradient">
              <div class="scroll-area-lg">
                <VuePerfectScrollbar class="scrollbar-container">
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">설비점검 지연</h4>
                          <p> DILUTOR********설비
                            <b class="text-danger">2021-01-05</b>
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <p> 인허가 검토요청 </p>
                          <p> 유해물질추가로 인한 인허가검토요청
                            <span class="text-success">2022-01-09</span>
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-success"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">설비점검일 도래</h4>
                          <p> 반응기 제 3호
                            <b class="text-danger">2021-01-13</b>
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">개선요청-조치</h4>
                          <p>
                            액면 - Low / 원인1에 대한 개선요청1
                            <b class="text-danger">2021-01-16</b>
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <h4 class="timeline-title">개선요청-조치</h4>
                          <p>
                            생산팀a 개선 조치 부탁드립니다.
                            <b class="text-danger">2021-01-17</b>
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab> -->
        </b-tabs>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      isBadge: false,
      apprCntUrl: '',
      apprCnt1: 0,
      apprCnt2: 0,
      apprCnt3: 0,
      apprCnt4: 0,
    };
  },
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.apprCntUrl = selectConfig.sys.appr.list.mycnt.url;
      this.getApprCnt();
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    getApprCnt() {
      this.$http.url = this.apprCntUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        approvalUserId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.apprCnt1 = _result.data.apprCnt1;
        this.apprCnt2 = _result.data.apprCnt2;
        this.apprCnt3 = _result.data.apprCnt3;
        this.apprCnt4 = _result.data.apprCnt4;
        if (this.apprCnt1 + this.apprCnt2 + this.apprCnt3 + this.apprCnt4 > 0) {
          this.isBadge = true;
        }
      },);
    }
  }
};
</script>
<style lang="sass">
.topnoti
  width: 26px
  height: 26px
  i
    margin-top: -8px
    margin-left: -6px
  .q-badge
    font-size: 8px !important
    min-width: 8px !important
    min-height: 8px !important
    width: 8px !important
    height: 8px !important
    padding: 0px
    margin-top: 5px
    margin-right: 5px
</style>
