<template>
  <div id="app">
      <component :is="layout">
        <!-- <navi></navi> -->
        <!-- transition tag는 애니메이션 효과를 주기 위함이고 현재는 fade in out 설정이 되어 있음 -->
        <transition name="fade" mode="out-in">
          <keep-alive>
            <router-view :key="$route.fullPath"></router-view>
          </keep-alive>
        </transition>
      </component>
  </div>
</template>

<style lang="scss">
// @import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "assets/base.scss";
@import "assets/psm.css";
</style>
<script>
const default_layout = "non"; // "default";
import Navi from '@/Layout/Components/Navi.vue'
import 'quasar/dist/quasar.min.css';
import {
  // useQuasar,
  // Loading,
  // optional!, for example below
  // with custom spinner
  QSpinnerGears
} from 'quasar'
export default {
  components: {
    Navi,
  },
  data() {
    return {
      loading: {
        loadingInstance: null,
        passCount: 0,
      },
    }
  },
  computed: {
    layout() {
      return ((this.$route && this.$route.meta.layout) || default_layout) + "-layout";
    }
  },
  created() {
    // TODO : AppMain을 전역 변수에 담는다.
    window.getApp = this;
    // this.$http.url = selectConfig.manage.dept.treelist.url;
    // this.$http.request(_result => {
    //   let deptTree = this.$comm.convertArrayToTree(_result.data, 'pdeptCd', 'deptCd');
    //   window.localStorage.setItem('deptTree', JSON.stringify(deptTree));
    // });
  },
  beforeMount() {
    /**
     * Message와 관련된 것
     */
    this.$on('ALERT', this.alert); // 사용
    this.$on('CONFIRM', this.confirm); // 사용
    this.$on('APP_REQUEST_SUCCESS', this.requestSuccess); // 사용
    this.$on('APP_REQUEST_ERROR', this.requestError); // 사용
    this.$on('NOTIFY_EXCEPTION', this.exceptionNotify); // 사용
    this.$on('APP_VALID_ERROR', this.validError); // 사용
    this.$on('LOADING_SHOW', this.loadingShow); // 사용
    this.$on('LOADING_HIDE', this.loadingHide); // 사용
  },
  mounted() {
  },
  beforeDestroy() {
    this.$off();
  },
  methods: {
    alert(_option) {
      this.$commMessage.alert(this, _option)
    },
    confirm(_option) {
      this.$commMessage.confirm(this, _option)
    },
    requestSuccess(_message) {
      this.alert({
        title: '안내', // 안내
        message: _message ? _message : '정상적으로 처리되었습니다.',
        type: 'success',
      });
    },
    requestError(_message, _format) {
      if (_message.response.data.returnCode != 'INIT_PASSWORD') {
        const _msg = _message.response ? _message.response.data.message : _message.message;
        if (!_message) return;
        this.alert({
          title: '에러', // 에러
          message: _msg,
          format: _format,
          type: 'error',
        });
      }
    },
    exceptionNotify(_option) {
      this.$commMessage.exceptionNotify(this, _option);
    },
    validError() {
      this.alert({
        title: '필수항목 미입력', // 유효성 에러
        message: '', // _message ? _message : '유효성 검사 중 오류가 발생했습니다. \n\r재시도 후 지속적인 문제 발생 시 관리자에게 문의하세요.',
        type: 'error',
      });
    },
    loadingShow() {
      if (this.loading.passCount === 0) {
        this.$q.loading.show({
          spinner: QSpinnerGears,
          spinnerColor: 'white',
          spinnerSize: 250,
          backgroundColor: 'blue',
          message: '잠시만 기다려주세요.',
          messageColor: 'white'
        })
      } else this.loading.passCount--;
    },
    loadingHide() {
      this.$nextTick(() => {
        if (this.loading) {
          this.$q.loading.hide()
        }
      });
    },
  }
};
</script>
