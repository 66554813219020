export default {
  props: {
  },
  computed: {
    convertLabel() {
      return this.label ? this.label : ''
    },
    convertTitle() {
      return this.title ? this.title : ''
    },
    convertPlaceholder() {
      return this.placeholder ? this.placeholder : ''
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
