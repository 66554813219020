var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-dialog",
        {
          ref: "dialog",
          attrs: {
            persistent: "",
            "no-esc-dismiss": "",
            "no-backdrop-dismiss": "",
            "content-class": {
              customDialog: true,
              "full-dialog": _vm.param.isFull,
              "mini-dialog": !_vm.param.isFull,
            },
            "content-style": { opacity: _vm.bodyOpacity },
            position: "top",
          },
          on: { show: _vm.onShow, hide: _vm.onHide },
          model: {
            value: _vm.param.visible,
            callback: function ($$v) {
              _vm.$set(_vm.param, "visible", $$v)
            },
            expression: "param.visible",
          },
        },
        [
          _c(
            "q-layout",
            {
              ref: "dialog-layout",
              staticClass: "bg-white no-scroll dialog-layout",
              style: {
                "max-width": _vm.param.isFull ? "none" : _vm.param.width,
                "min-height": "auto",
                "max-height": _vm.param.isFull ? "98%" : "calc(100vh - 60px)",
              },
              attrs: { view: "Lhh lpR fff" },
            },
            [
              _c(
                "q-header",
                {
                  ref: "dialog-header",
                  staticClass: "text-white dialog-header",
                  class: _vm.param.isFull
                    ? _vm.param.isApproval
                      ? "bg-mini-dialog cursor-move"
                      : "bg-full-dialog"
                    : "bg-mini-dialog cursor-move",
                },
                [
                  _c(
                    "q-toolbar",
                    { ref: "toolbar-header" },
                    [
                      _c("q-toolbar-title", [_vm._v(_vm._s(_vm.param.title))]),
                      _c(
                        "q-btn",
                        {
                          attrs: {
                            flat: "",
                            round: "",
                            dense: "",
                            icon: "close",
                          },
                          on: { click: _vm.closePopup },
                        },
                        [
                          _c(
                            "q-tooltip",
                            { staticClass: "bg-white text-primary" },
                            [_vm._v("창닫기")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-drawer",
                {
                  attrs: {
                    side: "right",
                    bordered: "",
                    width: 200,
                    breakpoint: 300,
                    "content-class": "bg-grey-3 q-pa-sm",
                  },
                  model: {
                    value: _vm.subMenuOpen,
                    callback: function ($$v) {
                      _vm.subMenuOpen = $$v
                    },
                    expression: "subMenuOpen",
                  },
                },
                _vm._l(50, function (n) {
                  return _c("div", { key: n }, [
                    _vm._v("Drawer " + _vm._s(n) + " / 50"),
                  ])
                }),
                0
              ),
              _c(
                "q-page-container",
                {
                  staticClass: "custom-dialog-page-container",
                  style: {
                    "overflow-y": "auto",
                    "min-height": "auto",
                    "max-height": _vm.param.isFull
                      ? "calc(100vh)"
                      : "calc(100vh - 100px)",
                  },
                },
                [
                  _c(
                    "q-page",
                    {
                      staticStyle: { "min-height": "200px" },
                      attrs: { padding: "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          style: {
                            height: _vm.param.isFull
                              ? "calc(100vh - 70px)"
                              : "auto",
                          },
                        },
                        [
                          _c(_vm.param.target, {
                            tag: "component",
                            style: `background:` + _vm.bodyColor,
                            attrs: {
                              popupParam: _vm.param.param,
                              contentHeight: _vm.contentHeight,
                              returnData: _vm.returnData,
                            },
                            on: { closePopup: _vm.param.closeCallback },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }