<!--
목적 : 도급업체 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customVendor"
      :class="[(stype==='customVendor' ? 'customVendor' : 'tableVendor')]"
      :dense="dense"
      stack-label
      filled
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        {{valueText}}
      </template>

      <template v-slot:append>
        <!-- <div v-if="!disabled&&editable" class="vendor-self cursor-pointer" @click="self" >자체</div> -->
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset(null)" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'c-vendor',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    stype: {
      type: String,
      default: 'customVendor',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit',
    },
    plantCd: {
      type: String,
      default: '',
    },
    processCd: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    companyFlag: {
      type: String,
      default: '',
    },
    haveProcess: {
      type: Boolean,
      default: false,
    },
    changeItem: {
      type: Object,
      default: () => {
        return {
          plant: '',
          vendor: '',
        }
      }
    },
  },
  data() {
    return {
      listUrl: '',
      vendors: [],
      vValue: null,
      valueText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.getList();
        // this.setValueText();
      },
      deep: true,
    },
    'changeItem.plant': {
      handler: function () {
        this.reset('plant');
      },
      deep: false,
    },
    disabled: {
      handler: function () {
        if (!this.disabled) {
          this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
        }
      },
      deep: false,
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.init();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customVendor'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    init() {
      this.listUrl = selectConfig.pjm.vendor.list.url;
      this.vValue = this.value;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd,
        processCd: this.processCd,
      }
      this.$http.request((_result) => {
        this.vendors = _result.data;
        this.setValueText();
      },);
    },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        if (this.vValue === this.$store.getters.user.selfVendorCd) {
          this.valueText = this.$store.getters.user.selfVendorName;
          return;
        }
        // let list = this.$store.getters.vendors;
        let vendor = this.$_.find(this.vendors, { vendorCd: this.vValue });
        if (vendor !== undefined) {
          this.valueText = vendor.vendorName;
        } else {
          this.valueText = '';
          let beforeVendorCd = this.$_.clone(this.vValue);
          this.$set(this.changeItem, 'vendor', uid())
          this.$emit('dataChange', null, beforeVendorCd, null);
          this.$emit('input', null);
        }
      } else {
        this.valueText = '';
      }
    },
    search() {
      if (this.haveProcess && !this.processCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공종을 먼저 선택하세요.', // 공종을 먼저 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '도급업체 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd,
        processCd: this.processCd,
        companyFlag: this.companyFlag
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let beforeVendorCd = this.$_.clone(this.vValue);
        this.valueText = data[0].vendorName;
        this.$set(this.changeItem, 'vendor', uid())
        this.$emit('dataChange', data[0].vendorCd, beforeVendorCd, data[0]);
        this.$emit('input', data[0].vendorCd);
      }
    },
    reset(flag) {
      let beforeVendorCd = this.$_.clone(this.vValue);
      this.valueText = '';
      this.$set(this.changeItem, 'vendor', uid())
      this.$emit('dataChange', null, beforeVendorCd, flag);
      this.$emit('input', null);
    },
    /**
     * 업체 리스트에 직영이 표시됨으로 해당 기능을 통해 직영 선택 X
     */
    // self() {
    //   // 직영으로
    //   let beforeVendorCd = this.$_.clone(this.vValue);
    //   this.valueText = '직영';
    //   this.$emit('dataChange', 'VEN0000000', beforeVendorCd, null);
    //   this.$emit('input', 'VEN0000000');
    // },
  },
};
</script>
<style lang="sass">
.customVendor
  padding-bottom: 14px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customVendor:hover
  .dateCloseIcon
    display: block
.customVendor.q-field--dense .q-field__control, .customVendor.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customVendor.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.tableVendor
  padding-bottom: 0px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.tableVendor:hover
  .dateCloseIcon
    display: block
.tableVendor.q-field--dense .q-field__control, .tableVendor.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.tableVendor.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
</style>