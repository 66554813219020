var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-dots" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            "toggle-class": "p-0 mr-2",
            "menu-class": "dropdown-menu-xl",
            variant: "link",
            "no-caret": "",
            right: "",
          },
        },
        [
          _c(
            "span",
            { attrs: { slot: "button-content" }, slot: "button-content" },
            [
              _c(
                "q-btn",
                {
                  staticClass: "topnoti",
                  attrs: {
                    flat: "",
                    dense: "",
                    round: "",
                    color: "white",
                    icon: "notifications",
                  },
                },
                [
                  _c("q-badge", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isBadge,
                        expression: "isBadge",
                      },
                    ],
                    class: [_vm.isBadge, "animatedBadge"],
                    attrs: { color: "red", rounded: "", floating: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "dropdown-menu-header mb-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "dropdown-menu-header-inner dropdown-menu-header-inner2 bg-orange-7",
              },
              [
                _c("div", {
                  staticClass: "menu-header-image opacity-1 dd-header-bg-2",
                }),
                _c("div", { staticClass: "menu-header-content text-white" }, [
                  _c("h5", { staticClass: "menu-header-title" }, [
                    _vm._v("알림"),
                  ]),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "nav-justified" },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "card-header-tab-animation",
                  attrs: { card: "" },
                },
                [
                  _c(
                    "b-tab",
                    {
                      staticClass: "p-3",
                      attrs: { title: "나의 결재", active: "" },
                    },
                    [
                      _c("div", { staticClass: "scroll-gradient" }, [
                        _c(
                          "div",
                          { staticClass: "scroll-area-sm" },
                          [
                            _c(
                              "VuePerfectScrollbar",
                              { staticClass: "scrollbar-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vertical-timeline-item vertical-timeline-element",
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-icon bounce-in",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "badge badge-dot badge-dot-xl badge-success",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-content bounce-in",
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "timeline-title timeline-title2",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goPage(
                                                        "/sys/appr/doc/apprTodo"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("결재할 문서 ")]
                                              ),
                                              _c(
                                                "p",
                                                [
                                                  _vm._v(" 결재 "),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "success",
                                                      label: _vm.apprCnt1,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                [
                                                  _vm._v(" 합의 "),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "success",
                                                      label: _vm.apprCnt2,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "p",
                                                [
                                                  _vm._v(" 확인 "),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "success",
                                                      label: _vm.apprCnt3,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", {
                                                staticClass:
                                                  "vertical-timeline-element-date",
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vertical-timeline-item vertical-timeline-element",
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-icon bounce-in",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "badge badge-dot badge-dot-xl badge-warning",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vertical-timeline-element-content bounce-in",
                                            },
                                            [
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "timeline-title timeline-title2",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goPage(
                                                        "/sys/appr/doc/apprPass"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "회람함 (최근 1주일) "
                                                  ),
                                                  _c("q-badge", {
                                                    attrs: {
                                                      rounded: "",
                                                      color: "warning",
                                                      label: _vm.apprCnt4,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("span", {
                                                staticClass:
                                                  "vertical-timeline-element-date",
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }