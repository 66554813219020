<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *  presentation
-->
<!-- // 에스엘용 수정 indigo- / 원본 teal- -->
<template>
  <q-stepper
    animated
    flat
    bordered
    header-nav
    alternative-labels
    ref="stepper"
    class="customStep full-height"
    done-color="red-6"
    active-color="orange-10"
    inactive-color="orange-3"
    :keep-alive="true"
    v-model="vValue"
    @transition="transition"
  >
    <!-- v-on:click="stepClick(item)" -->
    <q-step
      v-for="(item, idx) in stepItems"
      :key="idx"
      :name="item.name"
      :title="item.title"
      :icon="item.icon ? item.icon : 'content_paste'"
      :style="{ 'height': height }"
      :disable="disabled || item.disabled"
      :done="vValue > idx"
    >
      <keep-alive>
        <component
          v-if="item.component"
          :is="item.component"
          :param="item.param || param"
          :height.sync="height"
          @emitStep="emitStep"
        />
      </keep-alive>
    </q-step>


    <template v-slot:message>
      <template v-for="(item, idx) in stepItems">
        <template v-if="String(vValue) === stepItems[idx].name && item.message">
          <q-layout ref="custom-step-message" :key="idx" style="min-height:unset !important;">
            <q-page-container style="padding-top:0px;">
              <q-page class="custom-step-page">
                <q-page-sticky position="top-right" class="custom-step-offset">
                  <q-btn :icon="messageTranIcon" color="indigo-4" @click="tranMessage" size="2px"/>
                  <!-- // 에스엘용 수정 indigo-4 / 원본 teal-4 -->
                </q-page-sticky>
                <q-slide-transition>
                  <div v-show="visible">
                    <q-banner class="bg-grey-2 text-dark q-px-sm stepmsg">
                      <span v-html="item.message"></span>
                    </q-banner>
                  </div>
                </q-slide-transition>
              </q-page>
            </q-page-container>
          </q-layout>
        </template>
      </template>
    </template>
  </q-stepper>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-step',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Number, String],
      default: null,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    stepperGrpCd: {
      type: String,
      default: '',
    },
    currentStepCd: {
      type: String,
      default: '',
    },
    setSizeAgain: { // size 다시 조정
      type: String,
      default: '',
    },
    param: {
      type: [Object, Array],
    },
    stepDisabledItems: {
      type: Array,
      default: function() {
        return [];
      },
    },
    stepRejectItems: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      vValue: 1,
      visible: false,
      messageTranIcon: 'contact_support',
      height: 'auto',
      stepItems: [],
    };
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.vValue = this.$_.clone(this.value);
    },
    items() {
      this.stepItems = this.items;
    },
    currentStepCd() {
      if (this.stepItems && this.stepItems.length > 0) {
        let curIndex = this.currentStepCd ? this.$_.findIndex(this.stepItems, { name: this.currentStepCd }) : 0;
        if (curIndex > -1) {
          for (let i = 0; i <= curIndex; i++) {
            this.$set(this.stepItems[i], 'disabled', false)
          }
        }
      }
    },
    stepDisabledItems: {
      handler: function () {
        if (this.stepItems && this.stepItems.length > 0 && this.stepDisabledItems && this.stepDisabledItems.length > 0) {
          for (let i = 0; i < this.stepItems.length; i++) {
            if (this.$_.indexOf(this.stepDisabledItems, this.stepItems[i].name) > -1) {
              this.$set(this.stepItems[i], 'disabled', true)
            }
          }
        }
      },
      deep: true,
    },
    stepRejectItems: {
      handler: function () {
        if (this.stepItems && this.stepItems.length > 0 && this.stepRejectItems && this.stepRejectItems.length > 0) {
          for (let i = 0; i < this.stepItems.length; i++) {
            if (this.$_.indexOf(this.stepRejectItems, this.stepItems[i].name) > -1) {
              this.stepItems = this.$_.reject(this.stepItems, {
                name: this.stepItems[i].name,
              });
            }
          }
        }
      },
      deep: true,
    },
    setSizeAgain() {
      this.setSize();
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
    this.init();
  },
  mounted() {
    this.setSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      this.vValue = this.$_.clone(this.value);
      if (this.stepperGrpCd && (!this.items || this.items.length === 0)) {
        this.getItems();
      } else {
        this.stepItems = this.items;
      }
    },
    getItems() {
      this.$comm.getStepItems(this.stepperGrpCd).then(_result => {
        let curIndex = this.currentStepCd ? this.$_.findIndex(_result, { stepperMstCd: this.currentStepCd }) : 0;
        
        let checkDisabled = (this.stepDisabledItems && this.stepDisabledItems.length > 0)
        let checkReject = (this.stepRejectItems && this.stepRejectItems.length > 0)
        
        let index = 0;
        this.stepItems = [];
        this.$_.forEach(_result, item => {
          let disabled = curIndex < index
          if (checkDisabled && disabled === false) {
            disabled = (this.$_.indexOf(this.stepDisabledItems, item.stepperMstCd) > -1)
          }   
          
          if (!checkReject) {
            this.stepItems.push({
              name: item.stepperMstCd,
              title: item.stepperMstNm,
              icon: item.iconClass,
              disabled: disabled,
              message: this.$comm.convertHtml(item.remark),
              component: item.vuePath ? () => import(`@/pages${item.vuePath}.vue`) : null, // () => import(`${item.componentPath}`),
            });
            index++;
          } else {
            if (this.$_.indexOf(this.stepRejectItems, item.stepperMstCd) < 0) {
              this.stepItems.push({
                name: item.stepperMstCd,
                title: item.stepperMstNm,
                icon: item.iconClass,
                disabled: disabled,
                message: this.$comm.convertHtml(item.remark),
                component: item.vuePath ? () => import(`@/pages${item.vuePath}.vue`) : null, // () => import(`${item.componentPath}`),
              });
              index++;
            }
          }  
        })
        this.$emit('setStepItems')
      });
    },
    tranMessage() {
      this.visible = !this.visible;
      setTimeout(() => {
        this.setSize();
      }, 200);
    },
    setSize() {
      let messageHight = this.$refs['custom-step-message'] && this.$refs['custom-step-message'][0] && this.messageTranIcon === 'remove'? this.$refs['custom-step-message'][0].$el.offsetHeight : 0;
      let headerHeight = document.getElementsByClassName('q-stepper__header')[0].offsetHeight;
      let tempHeight = this.$refs['stepper'].$el.offsetHeight - (messageHight + (headerHeight < 10 ? 55 : headerHeight))
      if (tempHeight < 300) {
        tempHeight = 300;
      }
      this.height = String(tempHeight) + 'px';
    },
    transition(data) {
      this.setSize();
      this.stepClick(data);
    },
    emitStep(data) {
      this.$emit(data.name, data.param)
    },
    stepClick(item) {
      this.$emit('stepClick', item)
    }
  },
  // margin: 80px !important
};
</script>
<style lang="sass">
.customStep
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important
  border-radius: 0px
  .q-stepper__dot
    flex: none
  .q-stepper__tab
    padding: 8px 0px 2px 0px
  .q-stepper__label
    margin-top: 0.2rem
    padding: 0px
    .q-stepper__title
      font-size: 0.82rem
  .q-stepper__dot
    font-size: 14px
    width: 22px
    min-width: 22px
    height: 22px
    .q-icon
      font-size: 13px
  .q-splitter__panel
    padding: 0 !important

.custom-step-offset
  top: -23px
  right: 10px
  position: absolute
  transform: translateX(10px) !important
  z-index: 1

.q-stepper__tab
  min-height: 30px !important

.stepmsg
  .text-body2
    font-size: 0.75rem !important
</style>