var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-table", {
        directives: [{ name: "table-resizable", rawName: "v-table-resizable" }],
        ref: "compo-table",
        staticClass: "default-table",
        class: [_vm.topBorderClass, _vm.isDashboard ? "isDashboardTable" : ""],
        style: { height: _vm.height },
        attrs: {
          tableId: _vm.tableId,
          "card-class": _vm.cardClass,
          flat: "",
          bordered: "",
          fullscreen: _vm.fullscreen,
          separator: _vm.separator,
          data: _vm.arrayTreeObj,
          columns: _vm.arrangColumns,
          "row-key": _vm.rowKey,
          selection: _vm.selection,
          selected: _vm.selected,
          "visible-columns": _vm.visibleColumns,
          filter: _vm.filter,
          dense: _vm.dense,
          loading: _vm.loading,
          "no-data-label": _vm.noDataLabel,
          "no-results-label": _vm.noResultLabel,
          pagination: _vm.initialPagination,
          "hide-pagination": false,
          "virtual-scroll": !_vm.usePaging,
          "virtual-scroll-slice-size": !_vm.usePaging ? 20 : 20,
          "virtual-scroll-item-size": !_vm.usePaging ? 48 : 0,
          "virtual-scroll-sticky-size-start": !_vm.usePaging ? 48 : 0,
          "hide-bottom": _vm.hideBottom,
          "hide-header": _vm.hideHeader,
        },
        on: {
          "update:selected": function ($event) {
            _vm.selected = $event
          },
          "update:pagination": function ($event) {
            _vm.initialPagination = $event
          },
          selection: _vm.changeSelection,
          "virtual-scroll": _vm.virtualScroll,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "loading",
              fn: function () {
                return [
                  _c("q-inner-loading", {
                    attrs: { showing: "", color: "primary" },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "no-data",
              fn: function ({ message }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "full-width row flex-center text-accent q-gutter-sm gridNodataDiv",
                    },
                    [
                      _c("q-icon", {
                        attrs: { size: "2em", name: "sentiment_dissatisfied" },
                      }),
                      _c("span", [_vm._v(" " + _vm._s(message) + " ")]),
                    ],
                    1
                  ),
                ]
              },
            },
            _vm.isTop
              ? {
                  key: "top",
                  fn: function () {
                    return [
                      _vm.isTitle
                        ? [
                            _c(
                              "div",
                              { staticClass: "float-left" },
                              [
                                _vm._t("perfixTitle"),
                                _c(
                                  "div",
                                  { staticClass: "q-table__title" },
                                  [
                                    _c("i", {
                                      staticClass: "pe-7s-angle-right-circle",
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.convertTitle) + " "
                                    ),
                                    _vm._t("suffixTitle"),
                                  ],
                                  2
                                ),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "float-left",
                                class: [
                                  { gridSearchIcon: _vm.isTitle },
                                  { gridSearchIcon2: !_vm.isTitle },
                                ],
                              },
                              [
                                _vm.filtering
                                  ? _c("q-input", {
                                      staticClass: "filtering-text",
                                      staticStyle: {
                                        "min-width": "150px",
                                        "max-width": "250px",
                                      },
                                      attrs: {
                                        dense: "",
                                        debounce: "300",
                                        color: "white",
                                        placeholder: "Search",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function () {
                                              return [
                                                _c("q-icon", {
                                                  attrs: { name: "search" },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        4009527860
                                      ),
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._t("customFilter"),
                              ],
                              2
                            ),
                            _c("div", [_vm._t("table-chip")], 2),
                            _c("q-space"),
                            _c(
                              "div",
                              { staticClass: "q-mt-md gridbtntop" },
                              [_vm._t("table-button")],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "float-right" },
                              [
                                _vm._t("prefixContent"),
                                _vm.isExcelDown && _vm.data.length > 0
                                  ? _c(
                                      "q-btn",
                                      {
                                        staticClass: "tableExcelIcon",
                                        attrs: { flat: "", dense: "" },
                                        on: { click: _vm.downloadExcel },
                                      },
                                      [
                                        _c(
                                          "q-tooltip",
                                          {
                                            attrs: {
                                              anchor: "top left",
                                              self: "center left",
                                            },
                                          },
                                          [_vm._v(" 엑셀 다운로드 ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isFullScreen
                                  ? _c(
                                      "q-btn",
                                      {
                                        staticClass:
                                          "tabletopicon tablefullscreen",
                                        attrs: {
                                          flat: "",
                                          dense: "",
                                          color: "grey-6",
                                          icon: _vm.fullscreen
                                            ? "fullscreen_exit"
                                            : "fullscreen",
                                        },
                                        on: { click: _vm.clickFullScreen },
                                      },
                                      [
                                        _vm.fullscreen
                                          ? _c("q-tooltip", [
                                              _vm._v(" 원래대로 "),
                                            ])
                                          : _c(
                                              "q-tooltip",
                                              {
                                                attrs: {
                                                  anchor: "top left",
                                                  self: "center left",
                                                },
                                              },
                                              [_vm._v(" 전체화면 ")]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.columnSetting
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-setting gridsettingIcon",
                                      },
                                      [
                                        _c(
                                          "q-btn",
                                          {
                                            staticClass: "tabletopicon",
                                            attrs: {
                                              flat: "",
                                              dense: "",
                                              color: "grey-6",
                                              icon: "more_vert",
                                            },
                                          },
                                          [
                                            _c("q-tooltip", [
                                              _vm._v(" 컬럼설정 "),
                                            ]),
                                            _c(
                                              "q-menu",
                                              {
                                                attrs: {
                                                  "transition-show":
                                                    "jump-down",
                                                  "transition-hide": "jump-up",
                                                },
                                              },
                                              [
                                                _c(
                                                  "q-list",
                                                  {
                                                    staticClass:
                                                      "configColumnSet",
                                                    attrs: { dense: "" },
                                                  },
                                                  _vm._l(
                                                    _vm.columnsControl,
                                                    function (column, idx) {
                                                      return _c(
                                                        "q-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple",
                                                            },
                                                          ],
                                                          key: idx,
                                                          attrs: {
                                                            tag: "label",
                                                            disable:
                                                              column.disabled,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "q-item-section",
                                                            {
                                                              attrs: {
                                                                side: "",
                                                                top: "",
                                                              },
                                                            },
                                                            [
                                                              column.disabled
                                                                ? _c(
                                                                    "q-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "tableCheckBox",
                                                                      attrs: {
                                                                        disable: true,
                                                                        color:
                                                                          "orange-7",
                                                                        value: true,
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    "q-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "tableCheckBox",
                                                                      attrs: {
                                                                        color:
                                                                          "orange-7",
                                                                      },
                                                                      nativeOn:
                                                                        {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.colChange(
                                                                                column
                                                                              )
                                                                            },
                                                                        },
                                                                      model: {
                                                                        value:
                                                                          column.check,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              column,
                                                                              "check",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "column.check",
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "q-item-section",
                                                            [
                                                              _c(
                                                                "q-item-label",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      column.label
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._t("customTableTitleEditor"),
                          ]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.viewHeaders && _vm.viewHeaders.length > 0
              ? {
                  key: "header",
                  fn: function (props) {
                    return _vm._l(_vm.viewHeaders, function (viewHeader, idx) {
                      return _c(
                        "q-tr",
                        { key: idx, ref: "ctr", refInFor: true },
                        [
                          _vm.selection !== "none" && idx === 0 && _vm.editable
                            ? [
                                _c(
                                  "q-th",
                                  {
                                    staticClass: "text-center",
                                    class: _vm.fixFlag ? "checkbox-th" : "",
                                    staticStyle: {
                                      "min-width": "24px",
                                      "max-width": "24px",
                                    },
                                    attrs: {
                                      "auto-width": "",
                                      rowspan: _vm.colDepth,
                                    },
                                  },
                                  [
                                    _vm.selection === "multiple"
                                      ? _c("q-checkbox", {
                                          staticClass: "tableCheckBox",
                                          attrs: {
                                            color: "orange-7",
                                            disable: !_vm.editable,
                                            dense: "",
                                          },
                                          model: {
                                            value: props.selected,
                                            callback: function ($$v) {
                                              _vm.$set(props, "selected", $$v)
                                            },
                                            expression: "props.selected",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.isExpand && idx === 0
                            ? _c(
                                "q-th",
                                {
                                  staticClass: "text-center",
                                  attrs: {
                                    "auto-width": "",
                                    rowspan: _vm.colDepth,
                                  },
                                },
                                [_vm._t("expand-header")],
                                2
                              )
                            : _vm._e(),
                          _vm._t("perTh", null, null, props),
                          _vm._l(viewHeader, function (col) {
                            return [
                              (col.colspan === 1 ? idx + 1 === col.level : true)
                                ? _c(
                                    "q-th",
                                    {
                                      key: col.name,
                                      staticClass: "text-center",
                                      style: _vm.setHeaderStyle(col),
                                      attrs: {
                                        "auto-width": false,
                                        colspan: col.colspan,
                                        rowspan:
                                          col.colspan === 1 &&
                                          !col.hasOwnProperty("child")
                                            ? _vm.colDepth - idx
                                            : 1,
                                        props:
                                          _vm.$_.findIndex(_vm.arrangColumns, {
                                            name: col.name,
                                          }) > -1
                                            ? props
                                            : void 0,
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(col.label),
                                        },
                                      }),
                                      col.required
                                        ? _c(
                                            "font",
                                            { attrs: { color: "red" } },
                                            [_vm._v("*")]
                                          )
                                        : _vm._e(),
                                      col.helpcomment !== "" &&
                                      col.helpcomment !== null &&
                                      col.helpcomment !== undefined
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "q-btn-group",
                                                { attrs: { outline: "" } },
                                                [
                                                  _c(
                                                    "q-btn",
                                                    {
                                                      staticClass:
                                                        "inner-help-btn",
                                                      attrs: {
                                                        icon: "help",
                                                        color: "deep-purple-6",
                                                        "text-color": "white",
                                                        align: "center",
                                                        round: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-tooltip",
                                                        {
                                                          attrs: {
                                                            anchor:
                                                              "bottom left",
                                                            self: "top left",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tooltipCustomTop",
                                                            },
                                                            [_vm._v(" 설명 ")]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "tooltipCustom",
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "q-pl-xs",
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "left",
                                                                },
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      col.helpcomment
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      col.setHeader && col.type && _vm.editable
                                        ? _c(
                                            "div",
                                            [
                                              col.type === "select"
                                                ? [
                                                    _c("c-select", {
                                                      attrs: {
                                                        stype:
                                                          "tableHeaderselect",
                                                        editable: _vm.editable,
                                                        comboItems:
                                                          col.comboItems,
                                                        type: !col.none
                                                          ? "allEdit"
                                                          : "",
                                                        itemText: col.itemText
                                                          ? col.itemText
                                                          : "codeName",
                                                        itemValue: col.itemValue
                                                          ? col.itemValue
                                                          : "code",
                                                      },
                                                      on: {
                                                        datachange: (val) =>
                                                          _vm.headerDataChange(
                                                            val.value,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "check"
                                                ? [
                                                    _c("q-checkbox", {
                                                      staticClass:
                                                        "tableCheckBox",
                                                      attrs: {
                                                        dense: "",
                                                        color: "orange-7",
                                                        disable: !_vm.editable,
                                                        "true-value": col.true
                                                          ? col.true
                                                          : "O",
                                                        "false-value": col.false
                                                          ? col.false
                                                          : "X",
                                                      },
                                                      on: {
                                                        input: (val) =>
                                                          _vm.headerDataChange(
                                                            val,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value: col.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            col,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "col.value",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "date"
                                                ? [
                                                    _c("c-datepicker", {
                                                      staticClass:
                                                        "tableDatepicker",
                                                      attrs: {
                                                        dense: "",
                                                        type: "date",
                                                        disable: !_vm.editable,
                                                        range:
                                                          col.range === true
                                                            ? true
                                                            : false,
                                                      },
                                                      on: {
                                                        input: (val) =>
                                                          _vm.headerDataChange(
                                                            val,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value: col.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            col,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "col.value",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "text"
                                                ? [
                                                    col.type === "text"
                                                      ? _c("q-input", {
                                                          staticClass:
                                                            "tableHeaderText",
                                                          attrs: { dense: "" },
                                                          on: {
                                                            input: (val) =>
                                                              _vm.headerDataChange(
                                                                val,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                          model: {
                                                            value: col.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                col,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "col.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : col.type === "number"
                                                ? [
                                                    col.type === "number"
                                                      ? _c("q-input", {
                                                          staticClass:
                                                            "tableHeaderText",
                                                          attrs: {
                                                            dense: "",
                                                            type: "number",
                                                          },
                                                          on: {
                                                            input: (val) =>
                                                              _vm.headerDataChange(
                                                                val,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                          model: {
                                                            value: col.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                col,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "col.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : col.type === "custom"
                                                ? [
                                                    col.headType === "select"
                                                      ? _c("c-select", {
                                                          attrs: {
                                                            stype:
                                                              "tableHeaderselect",
                                                            editable:
                                                              _vm.editable,
                                                            comboItems:
                                                              col.comboItems,
                                                            type: !col.none
                                                              ? "allEdit"
                                                              : "",
                                                            itemText:
                                                              col.itemText
                                                                ? col.itemText
                                                                : "codeName",
                                                            itemValue:
                                                              col.itemValue
                                                                ? col.itemValue
                                                                : "code",
                                                          },
                                                          on: {
                                                            datachange: (val) =>
                                                              _vm.headerDataChange(
                                                                val.value,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    col.headType === "link"
                                                      ? _c("q-btn", {
                                                          staticClass:
                                                            "tableinnerBtn",
                                                          class: {
                                                            "tableinnerBtn-left":
                                                              col.align ===
                                                              "left"
                                                                ? true
                                                                : false,
                                                            "tableinnerBtn-right":
                                                              col.align ===
                                                              "right"
                                                                ? true
                                                                : false,
                                                          },
                                                          attrs: {
                                                            flat: "",
                                                            align: col.align,
                                                            size: col.size,
                                                            color: "blue-6",
                                                            icon: "build",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.headLinkClick(
                                                                props,
                                                                col
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    col.headType === "text"
                                                      ? _c("q-input", {
                                                          staticClass:
                                                            "tableHeaderText",
                                                          attrs: {
                                                            dense: "",
                                                            type: "text",
                                                          },
                                                          on: {
                                                            input: (val) =>
                                                              _vm.headerDataChange(
                                                                val,
                                                                props,
                                                                col
                                                              ),
                                                          },
                                                          model: {
                                                            value: col.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                col,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "col.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _vm._t("sufTh", null, null, props),
                        ],
                        2
                      )
                    })
                  },
                }
              : null,
            {
              key: "body",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    {
                      ref: "compo-tr",
                      class: [
                        {
                          "bg-light-blue-1": _vm.selectedRow(props),
                          "no-hover-row": _vm.noHoverRow,
                        },
                        _vm.customTrClass(props),
                      ],
                      attrs: { props: props },
                      on: {
                        click: (e) =>
                          _vm.rowClick(e, props.row, props.rowIndex),
                      },
                    },
                    [
                      _vm.selection !== "none" &&
                      _vm.editable &&
                      _vm.isCreate(props.row, -1, props.rowIndex)
                        ? _c(
                            "q-td",
                            {
                              staticClass: "text-center",
                              class: _vm.fixFlag ? "checkbox-td" : "",
                              staticStyle: {
                                "min-width": "24px",
                                "max-width": "24px",
                              },
                              attrs: {
                                "auto-width": "",
                                rowspan: _vm.getRowspan(-1, props.rowIndex),
                              },
                            },
                            [
                              _c("q-checkbox", {
                                staticClass: "tableCheckBox",
                                attrs: {
                                  color: "orange-7",
                                  disable:
                                    !_vm.editable ||
                                    props.row[_vm.checkDisableColumn],
                                  dense: "",
                                },
                                model: {
                                  value: props.selected,
                                  callback: function ($$v) {
                                    _vm.$set(props, "selected", $$v)
                                  },
                                  expression: "props.selected",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isExpand
                        ? _c(
                            "q-td",
                            {
                              staticClass: "text-center",
                              attrs: { "auto-width": "" },
                            },
                            [
                              _c("q-btn", {
                                staticClass: "tableInnerIsExpend",
                                attrs: {
                                  size: "xs",
                                  color: "accent",
                                  round: "",
                                  dense: "",
                                  icon: props.expand ? "remove" : "add",
                                },
                                on: {
                                  click: function ($event) {
                                    props.expand = !props.expand
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._t("perTd", null, null, props),
                      _vm._l(props.cols, function (col, colIndex) {
                        return [
                          _vm.isCreate(props.row, colIndex, props.rowIndex)
                            ? _c(
                                "q-td",
                                {
                                  key: col.name,
                                  ref:
                                    "custom-td-" +
                                    props.rowIndex +
                                    "-" +
                                    colIndex,
                                  refInFor: true,
                                  class: [
                                    _vm.checkEnable(col, props.row, props)
                                      ? ""
                                      : `edittd-${col.type}`,
                                  ],
                                  style: _vm.tdStyle(col),
                                  attrs: {
                                    "auto-width": false,
                                    props: props,
                                    rowspan: _vm.getRowspan(
                                      colIndex,
                                      props.rowIndex
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.tdClick(props, col, $event)
                                    },
                                    "!contextmenu": function ($event) {
                                      $event.preventDefault()
                                      return _vm.tdRightClick(props, col)
                                    },
                                  },
                                },
                                [
                                  _vm.contentsField(props, col)
                                    ? [
                                        _vm.check(col.type)
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.checkEnable(
                                                    col,
                                                    props.row,
                                                    props
                                                  )
                                                    ? "non-edit"
                                                    : "",
                                                  col.innerBtn
                                                    ? "descript-span"
                                                    : "",
                                                  col.color
                                                    ? "text-" + col.color
                                                    : "",
                                                ],
                                              },
                                              [
                                                colIndex !== 0
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props.row[col.name]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  : _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          "q-tree-link": true,
                                                          "q-tree-label": true,
                                                        },
                                                        style: _vm.setPadding(
                                                          props.row
                                                        ),
                                                      },
                                                      [
                                                        _c("q-icon", {
                                                          class: {
                                                            "cursor-pointer":
                                                              props.row
                                                                .children &&
                                                              props.row.children
                                                                .length > 0,
                                                          },
                                                          attrs: {
                                                            name: _vm.iconName(
                                                              props.row
                                                            ),
                                                            color: "secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggle(
                                                                props.row,
                                                                colIndex
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              props.row[
                                                                col.name
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                col.description
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "description-td",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              props.row[
                                                                col.description
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                col.innerBtn
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "q-btn-group",
                                                          {
                                                            staticClass:
                                                              "ColumInnerBtnGroup",
                                                            attrs: {
                                                              outline: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._l(
                                                              col.btns,
                                                              function (
                                                                btn,
                                                                idx
                                                              ) {
                                                                return [
                                                                  _vm.editable &&
                                                                  !_vm.checkEnable(
                                                                    col,
                                                                    props.row,
                                                                    props
                                                                  ) &&
                                                                  !_vm.checkInnerBtnEnable(
                                                                    btn,
                                                                    props.row,
                                                                    props
                                                                  )
                                                                    ? _c(
                                                                        "q-btn",
                                                                        {
                                                                          key: idx,
                                                                          staticClass:
                                                                            "ColumInnerBtn",
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                btn.label,
                                                                              icon: btn.icon
                                                                                ? btn.icon
                                                                                : void 0,
                                                                              color:
                                                                                btn.color
                                                                                  ? btn.color
                                                                                  : "blue-grey-4",
                                                                              "text-color":
                                                                                btn.textColor
                                                                                  ? btn.textColor
                                                                                  : "white",
                                                                              align:
                                                                                "center",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.innerBtnClicked(
                                                                                  col,
                                                                                  props,
                                                                                  btn
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          btn.tooltip
                                                                            ? _c(
                                                                                "q-tooltip",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      domProps:
                                                                                        {
                                                                                          innerHTML:
                                                                                            _vm._s(
                                                                                              btn.tooltip
                                                                                            ),
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm.editTdCheck(col.type)
                                          ? [
                                              col.type === "text"
                                                ? [
                                                    _c("c-text-column", {
                                                      attrs: {
                                                        editable: _vm.editable,
                                                        disabled:
                                                          props.row[
                                                            _vm
                                                              .checkDisableColumn
                                                          ] ||
                                                          _vm.checkEnable(
                                                            col,
                                                            props.row,
                                                            props
                                                          ),
                                                        col: col,
                                                        props: props,
                                                        colorClass:
                                                          _vm.checkColorCloass(
                                                            col,
                                                            props.row
                                                          ),
                                                      },
                                                      on: {
                                                        datachange: function (
                                                          $event
                                                        ) {
                                                          return _vm.datachange(
                                                            props,
                                                            col
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "textarea"
                                                ? [
                                                    _c("c-textarea-column", {
                                                      attrs: {
                                                        editable: _vm.editable,
                                                        disabled:
                                                          props.row[
                                                            _vm
                                                              .checkDisableColumn
                                                          ] ||
                                                          _vm.checkEnable(
                                                            col,
                                                            props.row,
                                                            props
                                                          ),
                                                        col: col,
                                                        props: props,
                                                      },
                                                      on: {
                                                        datachange: function (
                                                          $event
                                                        ) {
                                                          return _vm.datachange(
                                                            props,
                                                            col
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "select"
                                                ? [
                                                    _c("c-select", {
                                                      attrs: {
                                                        stype: "tableselect",
                                                        editable: _vm.editable,
                                                        disabled:
                                                          !_vm.editable ||
                                                          props.row[
                                                            _vm
                                                              .checkDisableColumn
                                                          ] ||
                                                          _vm.checkEnable(
                                                            col,
                                                            props.row,
                                                            props
                                                          ),
                                                        isChip: col.isChip,
                                                        comboItems:
                                                          col.comboItems,
                                                        type: !col.none
                                                          ? "edit"
                                                          : "",
                                                        itemText: col.itemText
                                                          ? col.itemText
                                                          : "codeName",
                                                        itemValue: col.itemValue
                                                          ? col.itemValue
                                                          : "code",
                                                      },
                                                      on: {
                                                        datachange: (val) =>
                                                          _vm.changeSelect(
                                                            val,
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          props.row[col.name],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.row,
                                                            col.name,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.row[col.name]",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "date"
                                                ? [
                                                    _c("c-datepicker", {
                                                      staticClass:
                                                        "tableDatepicker",
                                                      attrs: {
                                                        dense: "",
                                                        type: col.dateType
                                                          ? col.dateType
                                                          : "date",
                                                        disabled:
                                                          _vm.checkEnable(
                                                            col,
                                                            props.row,
                                                            props
                                                          ) ||
                                                          !_vm.editable ||
                                                          props.row[
                                                            _vm
                                                              .checkDisableColumn
                                                          ],
                                                        range:
                                                          col.range === true
                                                            ? true
                                                            : false,
                                                        minuteStep:
                                                          col.minuteStep
                                                            ? col.minuteStep
                                                            : 1,
                                                        start: col.start
                                                          ? col.start
                                                          : "",
                                                        end: col.end
                                                          ? col.end
                                                          : "",
                                                      },
                                                      on: {
                                                        datachange: (val) =>
                                                          _vm.datachange(
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          props.row[col.name],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.row,
                                                            col.name,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.row[col.name]",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "number"
                                                ? [
                                                    _c("c-number-column", {
                                                      attrs: {
                                                        editable: _vm.editable,
                                                        disabled:
                                                          props.row[
                                                            _vm
                                                              .checkDisableColumn
                                                          ] ||
                                                          _vm.checkEnable(
                                                            col,
                                                            props.row,
                                                            props
                                                          ),
                                                        col: col,
                                                        props: props,
                                                        colorClass:
                                                          _vm.checkColorCloass(
                                                            col,
                                                            props.row
                                                          ),
                                                      },
                                                      on: {
                                                        datachange: function (
                                                          $event
                                                        ) {
                                                          return _vm.datachange(
                                                            props,
                                                            col
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "check"
                                                ? [
                                                    _c("q-checkbox", {
                                                      staticClass:
                                                        "tableCheckBox",
                                                      attrs: {
                                                        dense: "",
                                                        color: "orange-7",
                                                        disable:
                                                          _vm.checkEnable(
                                                            col,
                                                            props.row,
                                                            props
                                                          ) || !_vm.editable,
                                                        "true-value": col.true
                                                          ? col.true
                                                          : "O",
                                                        "false-value": col.false
                                                          ? col.false
                                                          : "X",
                                                      },
                                                      on: {
                                                        input: (val) =>
                                                          _vm.datachange(
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          props.row[col.name],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.row,
                                                            col.name,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.row[col.name]",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "datetime"
                                                ? [
                                                    _c("c-datepicker", {
                                                      staticClass:
                                                        "tableDatepicker",
                                                      attrs: {
                                                        dense: "",
                                                        type: col.datetimeType
                                                          ? col.datetimeType
                                                          : "time",
                                                        timePickerOptions:
                                                          _vm.setTimePickerOptions(
                                                            props,
                                                            col
                                                          ),
                                                        range:
                                                          col.range === true
                                                            ? true
                                                            : false,
                                                        disabled:
                                                          _vm.checkEnable(
                                                            col,
                                                            props.row,
                                                            props
                                                          ) ||
                                                          !_vm.editable ||
                                                          props.row[
                                                            _vm
                                                              .checkDisableColumn
                                                          ],
                                                        minuteStep:
                                                          col.minuteStep
                                                            ? col.minuteStep
                                                            : 1,
                                                      },
                                                      on: {
                                                        datachange: (val) =>
                                                          _vm.datachange(
                                                            props,
                                                            col
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          props.row[col.name],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.row,
                                                            col.name,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.row[col.name]",
                                                      },
                                                    }),
                                                  ]
                                                : col.type === "multiSelect"
                                                ? _c("c-multi-select", {
                                                    attrs: {
                                                      stype: "tableselect",
                                                      editable: _vm.editable,
                                                      disabled: _vm.checkEnable(
                                                        col,
                                                        props.row,
                                                        props
                                                      ),
                                                      isObject: col.isObject,
                                                      valueText: col.valueText
                                                        ? col.valueText
                                                        : "codeName",
                                                      valueKey: col.valueKey
                                                        ? col.valueKey
                                                        : "code",
                                                      comboItems:
                                                        col.comboItems,
                                                      itemText: col.itemText
                                                        ? col.itemText
                                                        : "codeName",
                                                      itemValue: col.itemValue
                                                        ? col.itemValue
                                                        : "code",
                                                    },
                                                    on: {
                                                      datachange: (val) =>
                                                        _vm.datachange(
                                                          props,
                                                          col
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        props.row[col.name],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.row,
                                                          col.name,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.row[col.name]",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          : [
                                              col.type === "link"
                                                ? [
                                                    props.row[col.name]
                                                      ? _c("q-btn", {
                                                          staticClass:
                                                            "tableinnerBtn",
                                                          class: {
                                                            "tableinnerBtn-left":
                                                              col.align ===
                                                              "left"
                                                                ? true
                                                                : false,
                                                            "tableinnerBtn-right":
                                                              col.align ===
                                                              "right"
                                                                ? true
                                                                : false,
                                                          },
                                                          attrs: {
                                                            flat: "",
                                                            align: col.align,
                                                            color: "blue-6",
                                                            label:
                                                              props.row[
                                                                col.name
                                                              ],
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.linkClick(
                                                                props,
                                                                col
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                : col.type === "tag"
                                                ? [
                                                    props.row[col.name]
                                                      ? _c(
                                                          "q-chip",
                                                          {
                                                            attrs: {
                                                              color:
                                                                _vm.setTagColor(
                                                                  col,
                                                                  props.row[
                                                                    col.name
                                                                  ]
                                                                ),
                                                              "text-color":
                                                                col.colorItems
                                                                  ? "white"
                                                                  : "black",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.setTagName(
                                                                    col,
                                                                    props.row[
                                                                      col.name
                                                                    ]
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : void 0,
                                                  ]
                                                : col.type === "custom"
                                                ? [
                                                    _vm._t("customArea", null, {
                                                      props: props,
                                                      col: col,
                                                    }),
                                                  ]
                                                : col.type === "cost"
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "toThousandFilter"
                                                          )(props.row[col.name])
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : col.type === "html"
                                                ? _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$comm.convertEnter(
                                                          props.row[col.name]
                                                        )
                                                      ),
                                                    },
                                                  })
                                                : col.type === "badge"
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("q-badge", {
                                                        attrs: {
                                                          color: col.color,
                                                          label:
                                                            props.row[col.name],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : col.type === "proxy"
                                                ? _c(
                                                    "q-btn",
                                                    {
                                                      staticClass:
                                                        "tableinnerBtn",
                                                      attrs: {
                                                        flat: "",
                                                        align: col.align,
                                                        color: "blue-6",
                                                        label: props.row[
                                                          col.name
                                                        ]
                                                          ? props.row[col.name]
                                                          : "Click",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "q-popup-proxy",
                                                        {
                                                          ref:
                                                            "proxy_" +
                                                            props.rowIndex +
                                                            "_" +
                                                            col.name,
                                                          refInFor: true,
                                                          attrs: {
                                                            breakpoint: 1000,
                                                          },
                                                        },
                                                        [
                                                          _c(col.component, {
                                                            tag: "component",
                                                            attrs: {
                                                              props: props,
                                                              col: col,
                                                              editable:
                                                                _vm.editable,
                                                            },
                                                            on: {
                                                              callback: (
                                                                data,
                                                                color
                                                              ) =>
                                                                _vm.callbackProxy(
                                                                  data,
                                                                  color,
                                                                  props,
                                                                  col
                                                                ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      }),
                      _vm._t("sufTd", null, null, props),
                    ],
                    2
                  ),
                  _vm.showDescription
                    ? _c(
                        "q-tr",
                        {
                          key: `e_${props.rowIndex}`,
                          staticClass:
                            "q-virtual-scroll--with-prev description-tr",
                          attrs: { props: props },
                        },
                        [
                          _c(
                            "q-td",
                            {
                              staticClass: "description-td",
                              attrs: { colspan: "100%" },
                            },
                            [_vm._t("description-td", null, null, props)],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  props.expand
                    ? _c(
                        "q-tr",
                        { attrs: { props: props } },
                        [
                          _c(
                            "q-td",
                            { attrs: { colspan: "100%" } },
                            [_vm._t("expand-td", null, null, props)],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "bottom",
              fn: function () {
                return [
                  _vm._v(
                    " 총 건수 : " + _vm._s(_vm.data ? _vm.data.length : 0) + " "
                  ),
                  _c("q-space"),
                  _vm.usePaging
                    ? [
                        _c("q-select", {
                          staticClass: "tablePagingInput",
                          staticStyle: {
                            "min-width": "50px",
                            "max-width": "70px",
                          },
                          attrs: {
                            outlined: "",
                            dense: "",
                            options: _vm.pageItems,
                            color: "teal",
                          },
                          on: { input: _vm.changePageNumber },
                          model: {
                            value: _vm.pagePerRow.pageNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagePerRow, "pageNumber", $$v)
                            },
                            expression: "pagePerRow.pageNumber",
                          },
                        }),
                        _c("q-pagination", {
                          staticClass: "tablePagingBtn",
                          attrs: {
                            max: _vm.pagesNumber,
                            "max-pages": 5,
                            "direction-links": "",
                            "boundary-links": "",
                            "icon-first": "skip_previous",
                            "icon-last": "skip_next",
                            color: "teal-5",
                          },
                          model: {
                            value: _vm.initialPagination.page,
                            callback: function ($$v) {
                              _vm.$set(_vm.initialPagination, "page", $$v)
                            },
                            expression: "initialPagination.page",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }