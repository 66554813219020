<!--
목적 : 공사구역팝업 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customProjectArea"
      class="customProjectArea"
      :dense="dense"
      stack-label
      filled
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <q-chip
          v-for="(item, index) in setValueTextToArray(valueText)"
          :key="index"
          color="orange-6"
          text-color="white"
          class="q-ma-none customchipdept">
          {{item}}
        </q-chip>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'c-project-area',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '공사구역',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: 'single',
    },
    plantCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    plantCd() {
      /**
       * 공사현장이 변경될 시에 리셋처리
       */
      this.reset();
    }
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customProjectArea'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customProjectArea'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        this.$http.url = selectConfig.pjm.area.list.areaname;
        this.$http.type = 'GET';
        this.$http.param = {
          pjmProjectAreaId: this.vValue
        }
        this.$http.request((_result) => {
          this.valueText = _result.data.pjmProjectAreaName;
        },
        () => {
        });
      } else {
        this.valueText = '전체';
      }
    },
    setValueTextToArray(_str) {
      if (_str !== '') {
        return _str.split(',');
      } else {
        return null;
      }
    },
    search() {
      this.popupOptions.title = '공사구역 검색';
      this.popupOptions.param = {
        plantCd: this.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/project/projectAreaPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let pName = '';
        let pCd = '';
        if (this.multiple === 'single') {
          pName = data[0].pjmProjectAreaName
          pCd = data[0].pjmProjectAreaId
        } else {
          pName = this.$_.map(data, 'pjmProjectAreaName').join(',')
          pCd = this.$_.map(data, 'pjmProjectAreaId').join(',')
        }
        this.valueText = pName;
        this.$emit('pjmProjectAreaName', pName);
        this.$emit('input', pCd);
        this.$emit('datachange', data);
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('pjmProjectAreaName', null);
      this.$emit('datachange');
    },
  },
};
</script>
<style lang="sass">
.customProjectArea
  .q-field__control-container
    padding-bottom: 2px !important
  padding-bottom: 14px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 0px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customProjectArea:hover
  .dateCloseIcon
    display: block
.customProjectArea.q-field--dense .q-field__control, .customProjectArea.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 31px !important
  height: min-content !important
.customProjectArea.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.customchipdept.q-chip
  margin-top: -3px !important
  margin-right: 3px !important
  .q-chip__content
    font-size: 0.95em !important
</style>