<template>
  <div class="fix-height">
    <q-field
      ref="customField"
      class="customDate custom-field"
      :class="[beforeText ? 'before-field' : '']"
      :dense="dense"
      stack-label
      filled
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control @click.stop="search" class="cursor-pointer">
        <template v-if="!editing">
          {{valueText}}
        </template>
        <template v-else>
          <input class="col-12 custom-editing-input" 
            ref="custom-editing-input"
            type="text"
            v-model="valueText"
            @input="datachange" />
        </template>
      </template>

      <template v-slot:before>
        <template v-if="beforeText">
          <div class="custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white">
            {{beforeText}}
          </div>
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable&&isWrite" name="edit" @click="edit" 
          class="cursor-pointer"
          :class="[editing ? 'text-red' : '']"></q-icon>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
export default {
  /* attributes: name, components, props, data */
  name: 'c-field',
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    data: {
      type: Object,
    },
    deptValue: {
      type: String,
      default: 'deptCd',
    },
    plantValue: {
      type: String,
      default: 'plantCd',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'user', // user dept_user plant_dept_user
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    beforeText: {
      type: String,
      default: '',
    },
    plantCd: {
      type: String,
      default: '',
    },
    vendorCd: {
      type: String,
      default: '',
    },
    isInUserAll: {
      type: Boolean,
      default: false,
    },
    isOutUserAll: {
      type: Boolean,
      default: false,
    },
    changeItem: {
      type: Object,
      default: () => {
        return {
          plant: '',
          vendor: '',
        }
      }
    },
    isAllVendor: {
      type: Boolean,
      default: true,
    },
    isWrite: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: 'userName',
    },
    isVendorOrg: {
      type: Boolean,
      default: false,
    },
    isSelf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vValue: null,
      userList: null,
      valueText: '',
      editing: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      setEvent: false,
      userListUrl: '',
      vendorListUrl: '',
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
    'changeItem.plant': {
      handler: function () {
        this.reset();
      },
      deep: false,
    },
    'changeItem.vendor': {
      handler: function () {
        this.reset();
      },
      deep: false,
    },
    disabled() {
      if (!this.setEvent && !this.disabled) {
        this.setEvent = true;
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
      }
    },
    editing() {
      if (this.editing) {
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.search);
      } else {
        this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
      }
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.userListUrl = selectConfig.mdm.user.list.url
    this.vendorListUrl = selectConfig.mdm.user.vendor.list.url;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.setEvent = true;
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customField'].$el.getElementsByClassName('q-field__native')[0].removeEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    getUserList() {
      this.$http.url = this.userListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd,
        innerFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.$http.url = this.vendorListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.plantCd,
          vendorCd: this.vendorCd,
          searchFlag: 'Y',
          innerFlag: 'N',
        }
        this.$http.request((__result) => {
          this.userList = this.$_.concat(_result.data, __result.data);

          this.setValueText();
        },);
      },);
    },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        if (!this.userList) {
          this.getUserList();
        } else if (this.userList.length === 0) {
          this.$emit('input', null);
          this.valueText = '';
        } else {
          let user = this.$_.find(this.userList, { userId: this.vValue });
          if (user) {
            if (this.type === 'user') {
              this.valueText = user.userName
            } else if (this.type === 'dept_user') {
              let deptName = (this.$_.isUndefined(user.vendorName) 
                ? (this.data[this.deptValue] && this.data[this.deptValue] !== this.$store.getters.user.selfVendorCd
                  ? user.deptName 
                  : '시공사') 
                : user.vendorName);
              this.valueText = deptName + ' / ' + user.userName;
            } 
            // 건설에서는 하지 않음
            // else if (this.type === 'plant_dept_user') {
            //   this.valueText = user.plantName + ' / ' + user.deptName + ' / ' + user.userName;
            // }
          } else {
            this.$emit('input', null);
            this.valueText = '';
          }
        }
      } else {
        if (this.data && this.userName) {
          this.valueText = this.data[this.userName] || '';
        } else {
          this.valueText = '';
        }
      }
    },
    edit() {
      /**
       * key-in
       */
      if (!this.editing) {
        this.editing = true;
        setTimeout(() => {
          this.$refs['custom-editing-input'].focus();
        }, 50);
      } else {
        this.editing = false;
      }
      this.$emit('input', null);
      this.valueText = '';
    },
    search() {
      this.editing = false;
      this.popupOptions.title = '사용자 검색'; // 사용자 검색
      this.popupOptions.param = {
        isInUserAll: this.isInUserAll,
        isOutUserAll: this.isOutUserAll,
        isAllVendor: this.isAllVendor,
        isVendorOrg: this.isVendorOrg,
        isSelf: this.isSelf,
        type: 'single',
        plantCd: this.plantCd,
        vendorCd: this.vendorCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.type === 'dept_user') {
          if (data[0].deptCd) {
            this.data[this.deptValue] = data[0].deptCd
          } else if (data[0].vendorCd) {
            this.data[this.deptValue] = data[0].vendorCd
          } else {
            this.data[this.deptValue] = null
          }
        } 
        if (this.userName && this.data) {
          this.$set(this.data, this.userName, data[0].userName)
        }
        this.$emit('username', data[0].userName);
        this.$emit('input', data[0].userId);
        this.$emit('dataChange', data[0].userId);
      }
    },
    reset() {
      this.valueText = '';
      if (this.type === 'dept_user') {
        this.data[this.deptValue] = null
      } 
      if (this.userName && this.data) {
        this.$set(this.data, this.userName, this.valueText)
      }
      this.$emit('input', null);
      this.$emit('username', null);
      this.$emit('dataChange', null);
    },
    datachange(value) {
      this.$set(this.data, this.userName, this.valueText)
      this.$emit('dataChange', this.valueText);
    },
  },
};
</script>
<style lang="sass">
.customDate
  .q-field__before
    padding-top: 5px
.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important

.custom-field
  .q-field__native
    cursor: pointer
    .q-field__control
      border:0 !important
      padding:0px !important
    .custom-editing-input
      line-height: 18px !important
      padding: 0 !important
      border: 0
    .custom-editing-input:focus
      border: 0 !important
      outline: none
  </style>