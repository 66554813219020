
<template>
  <div class="fix-height">
    <q-file 
      ref="custom-pick-file"
      class="customFilePick"
      :class="[beforeText ? 'before-field' : '']"
      stack-label
      filled
      color="orange-7"
      :label="label"
      :disable="disabled||!editable"
      :dense="dense"
      :loading="loading"
      :clearable="!disabled&&editable"
      v-model="vValue"
      @input="input"
      @clear="clear">
      <template v-slot:prepend>
        <q-icon name="attach_file"></q-icon>
      </template>
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i class="pe-7s-note labelfrontIcon"></i>
            <font class="formLabelTitle">{{label}}</font>
          </b>
        </div>
      </template>

      <template v-slot:before>
        <template v-if="beforeText">
          <div class="custom-text-before q-pl-xs q-pr-xs bg-blue-7 text-white">
            {{beforeText}}
          </div>
        </template>
      </template>
    </q-file>
  </div>
</template>

<script>
export default {
  /* attributes: name, components, props, data */
  name: 'c-pick-file',
  props: {
    name: {
      type: String,
      default: 'pick-file',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: File,
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    beforeText: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  data() {
    return {
      vValue: null,
      imageData: null,
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  /* methods */
  methods: {
    input(item) {
      if (item) {
        let thisVue = this;
        const reader = new FileReader();
        reader.onload = e => {
          thisVue.imageData = e.target.result;
          thisVue.$emit('getImageData', thisVue.imageData);
        };
        reader.readAsDataURL(item);
      }

      this.$emit('input', item);
    },
    clear() {
      // 실제 파일 삭제처리
      // confirm창을 통해 삭제 처리하면 취소 했을 경우 value값을 통해 기존 값 유지
      this.vValue = null;
      this.$emit('input', null);
      this.$emit('getImageData', null);
    },
  },
};
</script>
<style lang="sass">
.customFilePick
  .q-field__inner
    padding: 0px !important
  .q-field__native.row
    padding-left: 3px
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
  .q-field__before
    padding-top: 5px
.customFilePick:hover
  .dateCloseIcon
    display: block
.customFilePick.q-field--dense .q-field__control, .customFilePick.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 33px !important
.customFilePick.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px

.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important
  </style>