var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isUploaderSetting || _vm.isMultiTask
    ? _c(
        "div",
        { staticClass: "fix-height row upload-picture-cover" },
        [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("q-uploader", {
                ref: _vm.uploadRef,
                staticClass: "custom-upload-picture",
                style: _vm.uploadStyle,
                attrs: {
                  flat: "",
                  bordered: "",
                  "with-credentials": "",
                  color: "custom-upload-color",
                  capture: "environment",
                  "field-name": "file",
                  url: _vm.url,
                  headers: _vm.headers,
                  method: "POST",
                  label: _vm.convertLabel,
                  "form-fields": _vm.formFields,
                  "auto-upload": false,
                  "hide-upload-btn": true,
                  multiple: _vm.uploaderSetting.multipleSelFlag === "Y",
                  accept: _vm.uploaderSetting.acceptExt,
                  "max-file-size": _vm.uploaderSetting.limitSize * 1000000,
                  "max-total-size":
                    _vm.uploaderSetting.limitSize *
                    1000000 *
                    _vm.uploaderSetting.limitCnt,
                  "max-files": _vm.maxFiles,
                  "no-thumbnails": _vm.uploaderSetting.previewFlag === "Y",
                },
                on: {
                  added: _vm.added,
                  finish: _vm.finish,
                  failed: _vm.failed,
                  rejected: _vm.rejected,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return undefined
                      },
                      proxy: true,
                    },
                    {
                      key: "list",
                      fn: function () {
                        return [
                          _vm.fileList && _vm.fileList.length > 0
                            ? _c(
                                "div",
                                { staticClass: "update-picture-card" },
                                [
                                  _c("q-img", {
                                    staticClass: "upload-picture",
                                    attrs: {
                                      src: _vm.fileList[_vm.previewImageIndex]
                                        .imgUrl,
                                      "spinner-color": "white",
                                      contain: "",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "info" },
                                    [
                                      _vm.fileList && _vm.fileList.length > 1
                                        ? _c("q-btn", {
                                            attrs: {
                                              unelevated: "",
                                              round: "",
                                              dense: "",
                                              size: "10px",
                                              color: "grey",
                                              icon: "chevron_left",
                                            },
                                            on: { click: _vm.beforePreView },
                                          })
                                        : _vm._e(),
                                      _c("q-btn", {
                                        attrs: {
                                          unelevated: "",
                                          round: "",
                                          dense: "",
                                          size: "10px",
                                          color: "primary",
                                          icon: "fullscreen",
                                        },
                                        on: { click: _vm.allImagePreView },
                                      }),
                                      _vm.editable && !_vm.isMultiTask
                                        ? _c("q-btn", {
                                            attrs: {
                                              unelevated: "",
                                              round: "",
                                              dense: "",
                                              size: "10px",
                                              color: "negative",
                                              icon: "delete_outline",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFile(
                                                  _vm.fileList[0]
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.fileList && _vm.fileList.length > 1
                                        ? _c("q-btn", {
                                            attrs: {
                                              unelevated: "",
                                              round: "",
                                              dense: "",
                                              size: "10px",
                                              color: "grey",
                                              icon: "navigate_next",
                                            },
                                            on: { click: _vm.afterPreView },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.editable && !_vm.isMultiTask
                                    ? [_c("q-uploader-add-trigger")]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : [
                                _c(
                                  "div",
                                  { staticClass: "empty_dscription" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.editable && !_vm.isMultiTask,
                                            expression:
                                              "editable&&!isMultiTask",
                                          },
                                        ],
                                        staticStyle: {
                                          "text-align": "center",
                                          padding: "5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("q-icon", {
                                              attrs: {
                                                size: "35px",
                                                name: "file_upload",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [_vm._v(" 사진을 올리세요 ")]
                                        ),
                                      ]
                                    ),
                                    _vm.editable && !_vm.isMultiTask
                                      ? [_c("q-uploader-add-trigger")]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4189065180
                ),
              }),
            ],
            1
          ),
          _vm.preview &&
          (_vm.preview.src ||
            (_vm.preview.fileList && _vm.preview.fileList.length > 0))
            ? _c(
                "q-dialog",
                {
                  model: {
                    value: _vm.preview.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.preview, "isShow", $$v)
                    },
                    expression: "preview.isShow",
                  },
                },
                [
                  _c(
                    "q-card",
                    { staticClass: "preview-image-card" },
                    [
                      _c(
                        "q-btn",
                        {
                          staticClass: "preview-close-btn",
                          attrs: {
                            color: "white",
                            "text-color": "primary",
                            round: "",
                            dense: "",
                            icon: "close",
                          },
                          on: {
                            click: function ($event) {
                              _vm.preview.isShow = false
                            },
                          },
                        },
                        [
                          _c(
                            "q-tooltip",
                            { staticClass: "bg-white text-primary" },
                            [_vm._v("창닫기")]
                          ),
                        ],
                        1
                      ),
                      _vm.preview.fileList && _vm.preview.fileList.length > 0
                        ? _c(
                            "q-carousel",
                            {
                              attrs: {
                                swipeable: "",
                                animated: "",
                                thumbnails: "",
                                infinite: "",
                              },
                              on: { transition: _vm.transitionImage },
                              model: {
                                value: _vm.preview.slide,
                                callback: function ($$v) {
                                  _vm.$set(_vm.preview, "slide", $$v)
                                },
                                expression: "preview.slide",
                              },
                            },
                            _vm._l(_vm.preview.fileList, function (file, idx) {
                              return _c("q-carousel-slide", {
                                key: idx,
                                attrs: {
                                  name: file.sysAttachFileId,
                                  "img-src": file.src,
                                },
                              })
                            }),
                            1
                          )
                        : _c("q-img", { attrs: { src: _vm.preview.src } }),
                      _c(
                        "q-card-section",
                        [
                          _c("q-btn", {
                            staticClass: "absolute",
                            staticStyle: {
                              top: "0",
                              right: "12px",
                              transform: "translateY(-50%)",
                            },
                            attrs: {
                              fab: "",
                              color: "primary",
                              icon: "save_alt",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fileDown(_vm.preview)
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "row no-wrap items-center" },
                            [
                              _c("div", { staticClass: "col text-h6" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.preview.oriFileNm) + " "
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-auto text-grey text-caption q-pt-md row no-wrap items-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getFileSizeTextByRound(
                                          _vm.preview.fileSize
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.preview.description ? _c("q-separator") : _vm._e(),
                      _vm.preview.description
                        ? _c("q-card-section", { staticClass: "q-pt-none" }, [
                            _c(
                              "div",
                              { staticClass: "text-subtitle1" },
                              [
                                _c("q-icon", { attrs: { name: "info" } }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$comm.getLangLabel("LBL0000586")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "text-caption text-grey" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.preview.description) + " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }