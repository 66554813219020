var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "tabcard"
        ? [
            _c(
              "q-toolbar",
              { staticClass: "custom-split-tab-card bg-grey-3 q-pa-none" },
              [
                _vm.haveTitle
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "q-pl-sm q-pr-sm bg-grey-8 text-white tab-title-have",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          width: "100px !important",
                          "min-height": "40px",
                          height: "100%",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { "font-weight": "900" } },
                          [
                            _c("q-icon", {
                              staticStyle: { "vertical-align": "sub" },
                              attrs: { name: "info", size: "xs" },
                            }),
                            _vm._v(" " + _vm._s(_vm.convertTitle) + " "),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.addTab && _vm.editable && !_vm.disabled
                  ? _c(
                      "div",
                      {
                        staticClass: "q-pl-sm q-pr-sm",
                        staticStyle: {
                          height: "100%",
                          display: "flex",
                          "align-items": "center",
                          width: "100px !important",
                          "min-height": "40px",
                        },
                        on: { click: _vm.add },
                      },
                      [
                        _c("q-btn", {
                          staticClass: "tab-add-btn",
                          attrs: {
                            rounded: "",
                            size: "xs",
                            color: "deep-orange-6",
                            icon: "add",
                            label: "추가",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "q-tabs",
                  {
                    ref: "compo-tab",
                    staticClass: "custom-split-tab2",
                    class: _vm.tabClass ? "bg-grey-3 mainBgColorsTxt" : "",
                    attrs: {
                      "inline-label": _vm.inlineLabel,
                      "outside-arrows": _vm.outsideArrows,
                      "mobile-arrows": _vm.mobileArrows,
                      align: _vm.align ? "left" : _vm.align,
                      "narrow-indicator": "",
                      dense: "",
                      shrink: "",
                    },
                    model: {
                      value: _vm.vValue,
                      callback: function ($$v) {
                        _vm.vValue = $$v
                      },
                      expression: "vValue",
                    },
                  },
                  [
                    _vm._l(_vm.tabItems, function (tab, idx) {
                      return _c(
                        "keep-alive",
                        { key: idx },
                        [
                          tab
                            ? [
                                _c(
                                  "q-tab",
                                  {
                                    class: tab.class,
                                    attrs: {
                                      disable:
                                        tab.disabled ||
                                        (_vm.tabInCheck && !tab.check) ||
                                        _vm.disabled ||
                                        !_vm.editable,
                                      name: tab.name,
                                      icon: tab.icon,
                                      label: tab.label,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tabClick(tab)
                                      },
                                    },
                                  },
                                  [
                                    tab.badge
                                      ? _c(
                                          "q-badge",
                                          {
                                            attrs: {
                                              color: "red",
                                              floating: "",
                                            },
                                          },
                                          [_vm._v(_vm._s(tab.badge))]
                                        )
                                      : _vm._e(),
                                    _vm.tabInCheck
                                      ? _c(
                                          "div",
                                          {
                                            class: [
                                              !_vm.disabled && _vm.editable
                                                ? "tab-check-non-disable"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c("q-checkbox", {
                                              attrs: {
                                                size: "xs",
                                                disable: _vm.disabled,
                                              },
                                              on: {
                                                input: (val) =>
                                                  _vm.changeCheckbox(val, tab),
                                              },
                                              model: {
                                                value: tab.check,
                                                callback: function ($$v) {
                                                  _vm.$set(tab, "check", $$v)
                                                },
                                                expression: "tab.check",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isRemoveTab(tab)
                                      ? _c(
                                          "div",
                                          {
                                            class: [
                                              !_vm.disabled && _vm.editable
                                                ? "tab-check-non-disable"
                                                : "",
                                              "q-pl-xs",
                                            ],
                                          },
                                          [
                                            _c("q-btn", {
                                              attrs: {
                                                flat: "",
                                                round: "",
                                                size: "3px",
                                                color: "red",
                                                icon: "close",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.remove(tab)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    _vm._t("customTab"),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "q-tab-panels",
              {
                staticClass: "tabcard-panel",
                style: { height: _vm.cusheight },
                attrs: {
                  animated: "",
                  vertical: "",
                  "transition-prev": "jump-left",
                  "transition-next": "jump-right",
                  "keep-alive": true,
                },
                model: {
                  value: _vm.vValue,
                  callback: function ($$v) {
                    _vm.vValue = $$v
                  },
                  expression: "vValue",
                },
              },
              [
                _vm._l(_vm.tabItems, function (tab, idx) {
                  return [
                    tab
                      ? [
                          _c(
                            "q-tab-panel",
                            {
                              key: idx,
                              attrs: { name: tab.name },
                              on: {
                                click: function ($event) {
                                  return _vm.tabClick(tab)
                                },
                              },
                            },
                            [
                              _c(
                                "keep-alive",
                                [_vm._t("default", null, null, tab)],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._t("customTabPanel"),
                        ]
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm.type === "vertical"
        ? _c("q-splitter", {
            ref: "compo-tab",
            staticClass: "custom-split-tab fit",
            class: _vm.tabClassVertical,
            attrs: { disable: true, unit: "px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "before",
                  fn: function () {
                    return [
                      _c(
                        "q-tabs",
                        {
                          staticClass: "custom-split-tab3",
                          class: _vm.tabClass2,
                          style: { height: _vm.cusheight },
                          attrs: {
                            vertical: "",
                            "inline-label": _vm.inlineLabel,
                            "outside-arrows": _vm.outsideArrows,
                            "mobile-arrows": _vm.mobileArrows,
                            align: _vm.align,
                            "switch-indicator": "",
                          },
                          model: {
                            value: _vm.vValue,
                            callback: function ($$v) {
                              _vm.vValue = $$v
                            },
                            expression: "vValue",
                          },
                        },
                        _vm._l(_vm.tabItems, function (tab, idx) {
                          return _c("q-tab", {
                            key: idx,
                            attrs: {
                              disable: tab.disabled || _vm.disabled,
                              name: tab.name,
                              icon: tab.icon,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.tabClick(tab)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("div", {
                                        staticClass: "q-tab__label",
                                        domProps: {
                                          innerHTML: _vm._s(tab.label),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "after",
                  fn: function () {
                    return [
                      _c(
                        "q-tab-panels",
                        {
                          staticClass: "custom-vertical-panels",
                          style: { height: _vm.cusheight },
                          attrs: {
                            animated: "",
                            vertical: "",
                            "transition-prev": "jump-up",
                            "transition-next": "jump-up",
                            "keep-alive": true,
                          },
                          model: {
                            value: _vm.vValue,
                            callback: function ($$v) {
                              _vm.vValue = $$v
                            },
                            expression: "vValue",
                          },
                        },
                        _vm._l(_vm.tabItems, function (tab, idx) {
                          return _c(
                            "q-tab-panel",
                            { key: idx, attrs: { name: tab.name } },
                            [
                              _c(
                                "keep-alive",
                                [_vm._t("default", null, null, tab)],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.splitter.data,
              callback: function ($$v) {
                _vm.$set(_vm.splitter, "data", $$v)
              },
              expression: "splitter.data",
            },
          })
        : _vm.type === "pagetop"
        ? [
            _c(
              "q-tabs",
              {
                ref: "compo-tab",
                staticClass: "custom-split-tab2 mainBgColorsPageTop text-white",
                attrs: {
                  "inline-label": _vm.inlineLabel,
                  "outside-arrows": _vm.outsideArrows,
                  "mobile-arrows": _vm.mobileArrows,
                  align: "left",
                  "switch-indicator": "",
                  dense: _vm.dense,
                },
                model: {
                  value: _vm.vValue,
                  callback: function ($$v) {
                    _vm.vValue = $$v
                  },
                  expression: "vValue",
                },
              },
              _vm._l(_vm.tabItems, function (tab, idx) {
                return _c(
                  "keep-alive",
                  { key: idx },
                  [
                    _c(
                      "q-tab",
                      {
                        class: tab.class,
                        attrs: {
                          disable: tab.disabled || _vm.disabled,
                          name: tab.name,
                          icon: tab.icon,
                          label: tab.label,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.tabClick(tab)
                          },
                        },
                      },
                      [
                        tab.badge
                          ? _c(
                              "q-badge",
                              { attrs: { color: "red", floating: "" } },
                              [_vm._v(_vm._s(tab.badge))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "q-tab-panels",
              {
                staticClass: "custom-panels",
                style: { height: _vm.cusheight },
                attrs: {
                  animated: "",
                  vertical: "",
                  "transition-prev": "jump-left",
                  "transition-next": "jump-right",
                  "keep-alive": true,
                },
                model: {
                  value: _vm.vValue,
                  callback: function ($$v) {
                    _vm.vValue = $$v
                  },
                  expression: "vValue",
                },
              },
              _vm._l(_vm.tabItems, function (tab, idx) {
                return _c(
                  "q-tab-panel",
                  { key: idx, attrs: { name: tab.name } },
                  [_c("keep-alive", [_vm._t("default", null, null, tab)], 2)],
                  1
                )
              }),
              1
            ),
          ]
        : [
            _c(
              "q-tabs",
              {
                ref: "compo-tab",
                staticClass: "custom-popuptop-tab",
                attrs: {
                  "inline-label": _vm.inlineLabel,
                  "outside-arrows": _vm.outsideArrows,
                  "mobile-arrows": _vm.mobileArrows,
                  align: _vm.align,
                  "switch-indicator": "",
                  dense: _vm.dense,
                },
                model: {
                  value: _vm.vValue,
                  callback: function ($$v) {
                    _vm.vValue = $$v
                  },
                  expression: "vValue",
                },
              },
              _vm._l(_vm.tabItems, function (tab, idx) {
                return _c(
                  "keep-alive",
                  { key: idx },
                  [
                    _c(
                      "q-tab",
                      {
                        class: tab.class,
                        attrs: {
                          disable: tab.disabled || _vm.disabled,
                          name: tab.name,
                          icon: tab.icon,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.tabClick(tab)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("div", {
                                    staticClass: "q-tab__label",
                                    style: _vm.tabOptions[tab.name]
                                      ? _vm.tabOptions[tab.name].tabLabelStyle
                                      : "",
                                    domProps: { innerHTML: _vm._s(tab.label) },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        tab.badge
                          ? _c(
                              "q-badge",
                              { attrs: { color: "red", floating: "" } },
                              [_vm._v(_vm._s(tab.badge))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "q-tab-panels",
              {
                staticClass: "custom-panels",
                style: { height: _vm.cusheight },
                attrs: {
                  animated: "",
                  vertical: "",
                  "transition-prev": "jump-left",
                  "transition-next": "jump-right",
                  "keep-alive": true,
                },
                model: {
                  value: _vm.vValue,
                  callback: function ($$v) {
                    _vm.vValue = $$v
                  },
                  expression: "vValue",
                },
              },
              _vm._l(_vm.tabItems, function (tab, idx) {
                return _c(
                  "q-tab-panel",
                  { key: idx, attrs: { name: tab.name } },
                  [_c("keep-alive", [_vm._t("default", null, null, tab)], 2)],
                  1
                )
              }),
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }