/**
 * 수정용 config
 */
let transactionConfig = {
  auth: {
    login: {
      insert: {
        url: '/api/auth/login',
      },
      logout: {
        url: '/api/auth/logout',
      },
      update: {
        url: '/api/auth/logout/{0}',
      },
    },
  },
  com: {
    upload: {
      uploading: {
        url: "/api/com/upload"
      },
      save: {
        url: "/api/com/update/taskKey"
      },
      delete: {
        url: "/api/com/upload/{0}"
      },
      allDelete: {
        url: "/api/com/upload/all/{0}/{1}"
      },
      copy: {
        url: "/api/com/upload/copy"
      },
    }
  },
  sys: {
    appr: {
      type: {
        insert: {
          url: '/api/sys/appr/type',
        },
        update: {
          url: '/api/sys/appr/type',
        },
        delete: {
          url: '/api/sys/appr/type/{0}',
        },
      },
      request: {
        insert: {
          url: '/api/sys/appr/request',
        },
        update: {
          url: '/api/sys/appr/request',
        },
        return: {
          url: '/api/sys/appr/request/return',
        },
        retrieve: {
          url: '/api/sys/appr/request/retrieve/{0}',
        }
      },
    },
    favorite: {
      save: {
        url: "/api/sys/favorites"
      },
      insert: {
        url: "/api/sys/favorite"
      },
      delete: {
        url: "/api/sys/favorite"
      },
    },
    menu: {
      insert: {
        url: '/api/sys/men/menu',
      },
      update: {
        url: '/api/sys/men/menu',
      },
      delete: {
        url: '/api/sys/men/menu/{0}',
      },
    },
    manual: {
      insert: {
        url: '/api/sys/men/manual',
      },
      update: {
        url: '/api/sys/men/manual',
      },
    },
    sequence: {
      insert: {
        url: '/api/sys/sequence',
      },
      update: {
        url: '/api/sys/sequence',
      },
      delete: {
        url: '/api/sys/sequence/{0}',
      },
    },
    attach: {
      insert: {
        url: '/api/sys/attachsetting',
      },
      update: {
        url: '/api/sys/attachsetting',
      },
      delete: {
        url: '/api/sys/attachsetting/{0}',
      },
    },
    excelUpload: {
      insert: {
        url: '/api/sys/excelUploadSetting',
      },
      update: {
        url: '/api/sys/excelUploadSetting',
      },
    },
    batch: {
      insert: {
        url: '/api/sys/batchsetting',
      },
      update: {
        url: '/api/sys/batchsetting',
      },
      delete: {
        url: '/api/sys/batchsetting/{0}',
      },
    },
    env: {
      insert: {
        url: '/api/sys/env',
      },
      update: {
        url: '/api/sys/env',
      },
      delete: {
        url: '/api/sys/env/{0}',
      },
    },
    defect: {
      insert: {
        url: '/api/sys/defect',
      },
      update: {
        url: '/api/sys/defect',
      },
      delete: {
        url: '/api/sys/defect/{0}',
      },
    },
    notice: {
      insert: {
        url: '/api/sys/notice',
      },
      update: {
        url: '/api/sys/notice',
      },
      delete: {
        url: '/api/sys/notice/{0}',
      },
    },
    code: {
      domain: {
        save: {
          url: "/api/sys/code/domain"
        },
        delete: {
          url: "/api/sys/code/domain"
        }
      },
      group: {
        save: {
          url: "/api/sys/code/grp"
        },
        delete: {
          url: "/api/sys/code/grp"
        }
      },
      mst: {
        save: {
          url: "/api/sys/code/mst"
        },
        delete: {
          url: "/api/sys/code/mst"
        }
      }
    },
    stepper: {
      grp: {
        save: {
          url: "/api/sys/stepper/grp"
        },
        delete: {
          url: "/api/sys/stepper/grp"
        }
      },
      mst: {
        save: {
          url: "/api/sys/stepper/mst"
        },
        delete: {
          url: "/api/sys/stepper/mst"
        }
      }
    },
    lbl: {
      mst: {
        save: {
          url: "/api/sys/label/mst"
        },
        delete: {
          url: "/api/sys/label/mst"
        }
      },
      dtl: {
        insert: {
          url: '/api/sys/label/dtl',
        },
        update: {
          url: '/api/sys/label/dtl',
        },
        delete: {
          url: '/api/sys/label/dtl/{0}',
        },
      }
    },
    msg: {
      mst: {
        save: {
          url: "/api/sys/msg/mst"
        },
        delete: {
          url: "/api/sys/msg/mst"
        }
      },
      dtl: {
        insert: {
          url: '/api/sys/msg/dtl',
        },
        update: {
          url: '/api/sys/msg/dtl',
        },
        delete: {
          url: '/api/sys/msg/dtl/{0}',
        },
      }
    },
    auth: {
      grp: {
        insert: {
          url: '/api/sys/auth/grp',
        },
        update: {
          url: '/api/sys/auth/grp',
        },
        delete: {
          url: '/api/sys/auth/grp/{0}',
        },
      },
      menu: {
        add: {
          url: '/api/sys/auth/menu/add',
        },
        save: {
          url: '/api/sys/auth/menu',
        },
        delete: {
          url: '/api/sys/auth/menu',
        },
      },
      dept: {
        insert: {
          url: '/api/sys/auth/dept',
        },
        delete: {
          url: '/api/sys/auth/dept',
        },
      },
      user: {
        insert: {
          url: '/api/sys/auth/user',
        },
        delete: {
          url: '/api/sys/auth/user',
        },
      },
      deptauth: {
        insert: {
          url: '/api/sys/auth/deptauth',
        },
        delete: {
          url: '/api/sys/auth/deptauth',
        },
      },
      userauth: {
        insert: {
          url: '/api/sys/auth/userauth',
        },
        delete: {
          url: '/api/sys/auth/userauth',
        },
      },
      plant: {
        insert: {
          url: '/api/sys/auth/plant',
        },
      },
    },
  },
  sas: {
    mst: {
      save: {
        url: "/api/sas/mst"
      },
      delete: {
        url: "/api/sas/mst"
      },
    },
    give: {
      save: {
        url: "/api/sas/give"
      },
      delete: {
        url: "/api/sas/give"
      },
    },
  },
  pjm: {
    vendorUser: {
      save: {
        url: "/api/pjm/user/vendor"
      },
      delete: {
        url: "/api/pjm/user/vendor"
      }
    },
    hhmtype: {
      save: {
        url: "/api/pjm/hhm/type"
      },
      delete: {
        url: "/api/pjm/hhm/type"
      },
    },
    info: {
      insert: {
        url: "/api/pjm/info"
      },
      update: {
        url: "/api/pjm/info"
      },
      delete: {
        url: "/api/pjm/info/{0}"
      },
    },
    book: {
      insert: {
        url: "/api/pjm/book/info"
      },
      update: {
        url: "/api/pjm/book/info"
      },
      delete: {
        summary: "/api/pjm/book/summary",
        instruct: "/api/pjm/book/instruct",
        measure: "/api/pjm/book/measure",
        plan: "/api/pjm/book/plan",
        safetycost: "/api/pjm/book/safetycost",
      },
    },
    uninjury: {
      insert: {
        url: "/api/pjm/uninjury"
      },
      update: {
        url: "/api/pjm/uninjury"
      },
      delete: {
        url: "/api/pjm/uninjury/{0}"
      },
    },
    org: {
      save: {
        url: "/api/pjm/org"
      },
      delete: {
        url: "/api/pjm/org"
      },
    },
    stackholder: {
      save: {
        url: "/api/pjm/stackholder"
      },
      delete: {
        url: "/api/pjm/stackholder"
      },
    },
    vendor: {
      save: {
        url: "/api/pjm/vendor"
      },
      delete: {
        url: "/api/pjm/vendor"
      },
      process: {
        person: {
          save: {
            url: "/api/pjm/vendor/process/person"
          },
          delete: {
            url: "/api/pjm/vendor/process/person"
          },
        },
      },
    },
    process: {
      save: {
        url: "/api/mdm/pim/project/process"
      },
      delete: {
        url: "/api/mdm/pim/project/process"
      },
    },
    hazard: {
      save: {
        url: "/api/mdm/sop/project/hazard"
      },
      delete: {
        url: "/api/mdm/sop/project/hazard"
      },
    },
    mst: {
      save: {
        url: "/api/pjm/process/mst"
      },
    },
    other: {
      save: {
        url: "/api/pjm/process/other"
      },
    },
    area: {
      save: {
        url: "/api/pjm/area"
      },
      delete: {
        url: "/api/pjm/area"
      },
      process: {
        save: {
          url: "/api/mdm/pim/project/area/process"
        },
        delete: {
          url: "/api/mdm/pim/project/area/process"
        },
      },
    },
    activity: {
      mst: {
        save: {
          url: "/api/pjm/activity/mst"
        },
      },
      activity: {
        save: {
          url: "/api/pjm/activity/activity"
        },
      },
    },
  },
  smf: {
    usePlan: {
      insert: {
        url: "/api/smf/usePlan"
      },
      update: {
        url: "/api/smf/usePlan"
      },
      delete: {
        url: "/api/smf/usePlan/{0}"
      },
    },
    monthlyPerformance: {
      insert: {
        url: "/api/smf/monthlyPerformance"
      },
      update: {
        url: "/api/smf/monthlyPerformance"
      },
      delete: {
        url: "/api/smf/monthlyPerformance/{0}"
      },
    },
    monthlyPerformanceVendor: {
      permit: {
        url: "/api/smf/monthlyPerformanceVendor/permit"
      },
      save: {
        url: "/api/smf/monthlyPerformanceVendor"
      },
      delete: {
        url: "/api/smf/monthlyPerformanceVendor"
      }
    },
  },
  sop: {
    iim: {
      accident: {
        near: {
          insert: {
            url: "/api/sop/iim/accident/near"
          },
          update: {
            url: "/api/sop/iim/accident/near"
          },
          delete: {
            url: "/api/sop/iim/accident/near/{0}",
            victim: {
              url: "/api/sop/iim/accident/near/victim",
            }
          },
          complete: {
            url: "/api/sop/iim/accident/near/complete"
          },
          prevention: {
            update: {
              url: "/api/sop/iim/accident/near/prevention",
              cause: {
                url: "/api/sop/iim/accident/near/prevention/causes"
              }
            },
            delete: {
              url: "/api/sop/iim/accident/near/prevention"
            },
            complete: {
              url: "/api/sop/iim/accident/near/prevention/complete"
            },
          }
        },
      }
    },
    edu: {
      course: {
        insert: {
          url: "/api/sop/edu/course/info"
        },
        update: {
          url: "/api/sop/edu/course/info"
        },
      },
      result: {
        insert: {
          url: "/api/sop/edu/result",
        },
        update: {
          url: "/api/sop/edu/result"
        },
        delete: {
          url: "/api/sop/edu/result/{0}",
        },
        complete: {
          url: "/api/sop/edu/result"
        },
        complete2: {
          url: "/api/sop/edu/complete/result/sec"
        },
      },
    },
    work: {
      loc: {
        insert: {
          url: "/api/sop/loc/work"
        },
        update: {
          url: "/api/sop/loc/work"
        },
        deletes: {
          url: "/api/sop/loc/work"
        },
        delete: {
          url: "/api/sop/loc/work/{0}"
        },
      }
    },
    lim: {
      lincense: {
        kind: {
          save: {
            url: "/api/sop/lim/licensekind"
          },
          delete: {
            url: "/api/sop/lim/licensekind"
          },
        },
        request: {
          insert: {
            url: "/api/sop/lim/license/request"
          },
          update: {
            url: "/api/sop/lim/license/request"
          },
          delete: {
            url: "/api/sop/lim/license/request/{0}"
          },
        },
        review: {
          insert: {
            url: "/api/sop/lim/license/review"
          },
          update: {
            url: "/api/sop/lim/license/review"
          },
          delete: {
            url: "/api/sop/lim/license/review/{0}"
          },
        },
        card: {
          insert: {
            url: "/api/sop/lim/license/card"
          },
          update: {
            url: "/api/sop/lim/license/card"
          },
          delete: {
            url: "/api/sop/lim/license/card/{0}"
          },
        },
        equipment: {
          insert: {
            url: "/api/sop/lim/license/equipment"
          },
          delete: {
            url: "/api/sop/lim/license/equipment"
          },
        },
        material: {
          insert: {
            url: "/api/sop/lim/license/material"
          },
          delete: {
            url: "/api/sop/lim/license/material"
          },
        },
      },
    },
    ibm: {
      improve: {
        insert: {
          url: "/api/sop/ibm/improvement"
        },
        insert2: {
          url: "/api/sop/ibm/improvements"
        },
        update: {
          url: "/api/sop/ibm/improvement"
        },
        delete: {
          url: "/api/sop/ibm/improvement/{0}"
        },
      },
    },
    psr: {
      checkItem: {
        insert: {
          url: "/api/sop/psr/prestartup/checkItem"
        },
        update: {
          url: "/api/sop/psr/prestartup/checkItem"
        },
      },
      check: {
        insert: {
          url: "/api/sop/psr/prestartupCheck"
        },
        update: {
          url: "/api/sop/psr/prestartupCheck"
        },
        delete: {
          url: "/api/sop/psr/prestartupCheck/{0}"
        },
        complete: {
          url: "/api/sop/psr/prestartup/complete"
        },
        equipment: {
          complete: {
            url: "/api/sop/psr/prestartup/equipment/complete"
          },
        }
      },
    },
    swp: {
      workChekItem: {
        save: {
          url: "/api/sop/swp/checkItem",
        },
      },
      gas: {
        save: {
          url: "/api/sop/swp/gas"
        },
      },
      map: {
        save: {
          url: "/api/sop/swp/map"
        },
      },
      workPermit: {
        insert: {
          url: "/api/sop/swp/workPermit"
        },
        update: {
          url: "/api/sop/swp/workPermit"
        },
        delete: {
          url: "/api/sop/swp/workPermit/{0}"
        },
        approval: {
          url: "/api/sop/swp/workPermit/approval"
        },
        over: {
          url: "/api/sop/swp/workPermit/over"
        },
        cancel: {
          url: "/api/sop/swp/workPermit/cancel"
        },
        complete: {
          url: "/api/sop/swp/workPermit/complete"
        },
      },
    },
    moc: {
      change: {
        insert: {
          url: "/api/sop/moc/moc"
        },
        update: {
          url: "/api/sop/moc/moc"
        },
        delete: {
          url: "/api/sop/moc/moc/{0}"
        },
        complete: {
          url: "/api/sop/moc/moc/complete"
        },
      },
      checklist: {
        save: {
          url: "/api/sop/moc/moc/checklist"
        },
        delete: {
          url: "/api/sop/moc/moc/checklist"
        },
      },
      review: {
        save: {
          url: "/api/sop/moc/moc/review"
        },
        delete: {
          url: "/api/sop/moc/moc/review"
        },
      },
      committee: {
        save: {
          url: "/api/sop/moc/moc/committee"
        },
        delete: {
          url: "/api/sop/moc/moc/committee"
        },
      },
      committeeReviewItem: {
        save: {
          url: "/api/sop/moc/moc/committee/reviewItem"
        },
        delete: {
          url: "/api/sop/moc/moc/committee/reviewItem"
        },
      },
      committeeHold: {
        save: {
          url: "/api/sop/moc/moc/committeeHold"
        },
        approval: {
          url: "/api/sop/moc/moc/committeeHold/approval"
        },
        keep: {
          url: "/api/sop/moc/moc/committeeHold/keep"
        },
      },
      relatedWork: {
        save: {
          url: "/api/sop/moc/moc/relatedWorks"
        },
        singleSave: {
          url: "/api/sop/moc/moc/relatedWork"
        },
        complete: {
          url: "/api/sop/moc/moc/relatedWork/complete"
        },
        delete: {
          url: "/api/sop/moc/moc/relatedWork"
        },
      },
      relatedWorkDocument: {
        save: {
          url: "/api/sop/moc/moc/relatedWorkDocument"
        },
      },
      restorationEquipment: {
        update: {
          url: "/api/sop/moc/moc/restorationEquipment"
        },
      },
      determination: {
        save: {
          url: "/api/sop/moc/determination/checklist"
        },
      }
    },
    min: {
      machinery: {
        insert: {
          url: "/api/min/hhm/machinery/machinery",
          history: {
            url: "/api/min/hhm/machinery/history"
          },
          result: {
            url: "/api/min/hhm/machinery/result"
          }
        },
        update: {
          url: "/api/min/hhm/machinery/machinery",
          result: {
            url: "/api/min/hhm/machinery/result"
          }
        },
        delete: {
          url: "/api/min/hhm/machinery/machinery/{0}",
          history: {
            url: "/api/min/hhm/machinery/history"
          },
          result1: {
            url: "/api/min/hhm/machinery/result"
          },
          result2: {
            url: "/api/min/hhm/machinery/result/{0}/{1}"
          }
        },
      },
      inspectitem: {
        save: {
          url: "/api/min/hhm/type/inspect"
        },
        delete: {
          url: "/api/min/hhm/type/inspect"
        },
      },
      equipment: {
        plan: {
          insert: {
            url: '/api/sop/min/equipment/plan'
          },
          update: {
            url: '/api/sop/min/equipment/plan'
          },
          complete: {
            url: '/api/sop/min/equipment/plan/complete'
          },
          delete: {
            url: '/api/sop/min/equipment/plan'
          }
        },
        result: {
          insert: {
            url: '/api/sop/min/equipment/result'
          },
          update: {
            url: '/api/sop/min/equipment/result'
          },
          updates: {
            url: '/api/sop/min/equipment/results'
          },
          updateItem: {
            url: '/api/sop/min/equipment/result/items'
          },
          complete: {
            url: '/api/sop/min/equipment/result/complete'
          },
          completes: {
            url: '/api/sop/min/equipment/result/completes'
          },
          delete: {
            url: '/api/sop/min/equipment/result'
          }
        },
        improve: {
          insert: {
            url: "/api/sop/min/equipment/improve"
          },
          update: {
            url: "/api/sop/min/equipment/improve"
          },
          delete: {
            url: "/api/sop/min/equipment/improve"
          }
        },
      },
      maintenance: {
        plan: {
          insert: {
            url: '/api/sop/min/maintenance/plan'
          },
          update: {
            url: '/api/sop/min/maintenance/plan'
          },
          complete: {
            url: '/api/sop/min/maintenance/plan/complete'
          },
          delete: {
            url: '/api/sop/min/maintenance/plan'
          }
        },
        result: {
          insert: {
            url: '/api/sop/min/maintenance/result'
          },
          update: {
            url: '/api/sop/min/maintenance/result'
          },
          updates: {
            url: '/api/sop/min/maintenance/results'
          },
          complete: {
            url: '/api/sop/min/maintenance/result/complete'
          },
          completes: {
            url: '/api/sop/min/maintenance/result/completes'
          },
          delete: {
            url: '/api/sop/min/maintenance/result'
          }
        }
      }
    },
    hhm: {
      hazard: {
        check: {
          insert: {
            url: "/api/sop/hhm/hazard/equipment"
          },
          update: {
            url: "/api/sop/hhm/hazard/equipment"
          },
          delete: {
            url: "/api/sop/hhm/hazard/equipment/{0}"
          }
        },
        hazardHistory: {
          insert: {
            url: "/api/sop/hhm/hazard/equipment/history"
          },
          update: {
            url: "/api/sop/hhm/hazard/equipment/history"
          },
          delete: {
            url: "/api/sop/hhm/hazard/equipment/history"
          }
        },
        hazardImprove: {
          insert: {
            url: "/api/sop/hhm/hazard/equipment/improve"
          },
          update: {
            url: "/api/sop/hhm/hazard/equipment/improve"
          },
          delete: {
            url: "/api/sop/hhm/hazard/equipment/improve"
          }
        }
      },
    },
  },
  mdm: {
    cmt: {
      insert: {
        url: "/api/mdm/cmt/commute"
      },
      update: {
        url: "/api/mdm/cmt/commute"
      },
      delete: {
        url: "/api/mdm/cmt/commute/{0}"
      },
    },
    cmd: {
      workCheckItem: {
        save: {
          url: "/api/mdm/cmd/checkItemMst",
        },
      },
      gas: {
        save: {
          url: "/api/mdm/cmd/gasMst"
        },
      },
      checkItem: {
        save: {
          url: "/api/mdm/cmd/itemMst"
        },
        delete: {
          url: "/api/mdm/cmd/itemMst"
        }
      },
      codeMst: {
        save: {
          url: "/api/mdm/cmd/codeMst"
        },
        delete: {
          url: "/api/mdm/cmd/codeMst"
        }
      },
      inspectitem: {
        save: {
          url: "/api/mdm/cmd/inspectitemMst"
        },
        delete: {
          url: "/api/mdm/cmd/inspectitemMst"
        }
      }
    },
    user: {
      insert: {
        url: "/api/mdm/hrm/user"
      },
      update: {
        url: "/api/mdm/hrm/user"
      },
      delete: {
        url: "/api/mdm/hrm/user/{0}"
      },
      changepw: {
        url: "/api/mdm/hrm/changepw"
      },
      reset: {
        url: '/api/mdm/hrm/resetpwd',
      },
    },
    dept: {
      insert: {
        url: "/api/mdm/dim/dept"
      },
      update: {
        url: "/api/mdm/dim/dept"
      },
      delete: {
        url: "/api/mdm/dim/dept/{0}"
      },
    },
    process: {
      insert: {
        url: "/api/mdm/pim/process"
      },
      update: {
        url: "/api/mdm/pim/process"
      },
      delete: {
        url: "/api/mdm/pim/process/{0}"
      },
    },
    construct: {
      insert: {
        url: "/api/mdm/pim/construct"
      },
      update: {
        url: "/api/mdm/pim/construct"
      },
      delete: {
        url: "/api/mdm/pim/construct/{0}"
      },
    },
    cim: {
      vendor: {
        insert: {
          url: "/api/mdm/cim/vendor"
        },
        update: {
          url: "/api/mdm/cim/vendor"
        },
        selfUpdate: {
          url: "/api/mdm/cim/selfvendor"
        },
        delete: {
          url: "/api/mdm/cim/vendor/{0}"
        },
        reset: {
          url: "/api/mdm/cim/vendorpwd"
        },
        item: {
          save: {
            url: "/api/mdm/cim/vendoreval/item"
          },
          delete: {
            url: "/api/mdm/cim/vendoreval/item"
          },
        },
        input: {
          save: {
            url: "/api/mdm/cim/vendoreval/item"
          },
          delete: {
            url: "/api/mdm/cim/vendoreval/item"
          },
        },
        result: {
          insert: {
            url: "/api/mdm/cim/vendoreval/result"
          },
          update: {
            url: "/api/mdm/cim/vendoreval/result"
          },
          delete: {
            url: "/api/mdm/cim/vendoreval/result/{0}"
          },
          user: {
            save: {
              url: "/api/mdm/cim/vendoreval/resultuser"
            },
          },
          item: {
            save: {
              url: "/api/mdm/cim/vendoreval/resultitem"
            },
            delete: {
              url: "/api/mdm/cim/vendoreval/resultitem"
            },
          },
        }
      },
    },
    sop: {
      job: {
        insert: {
          url: "/api/mdm/sop/sop"
        },
        update: {
          url: "/api/mdm/sop/sop"
        },
        delete: {
          url: "/api/mdm/sop/sop/{0}"
        },
        revision: {
          url: "/api/mdm/sop/sop/revision/{0}"
        },
      },
      step: {
        save: {
          url: "/api/mdm/sop/step"
        },
        delete: {
          url: "/api/mdm/sop/step"
        },
      },
      hazard: {
        save: {
          url: "/api/mdm/sop/hazard"
        },
        delete: {
          url: "/api/mdm/sop/hazard"
        },
      },
      reason: {
        save: {
          url: "/api/mdm/sop/sop"
        },
        update: {
          url: "/api/mdm/sop/sop"
        },
        delete: {
          url: "/api/mdm/sop/sop"
        },
      },
      mm: {
        insert: {
          url: "/api/mdm/sop/mm"
        },
        delete: {
          url: "/api/mdm/sop/mm"
        },
      },
      chemmst: {
        insert: {
          url: "/api/mdm/sop/chemmst"
        },
        delete: {
          url: "/api/mdm/sop/chemmst"
        },
      },
      chem: {
        insert: {
          url: "/api/mdm/sop/chem"
        },
        delete: {
          url: "/api/mdm/sop/chem"
        },
      },
      checkitemmst: {
        save: {
          url: "/api/mdm/sop/checkitemmst"
        },
        delete: {
          url: "/api/mdm/sop/checkitemmst"
        },
      },
      checkitem: {
        save: {
          url: "/api/mdm/sop/checkitem"
        },
        delete: {
          url: "/api/mdm/sop/checkitem"
        },
      },
    },
    senior: {
      save: {
        url: '/api/mdm/senior/senior',
      },
      delete: {
        url: '/api/mdm/senior/senior',
      },
      status: {
        insert: {
          url: '/api/mdm/senior/status',
        },
        update: {
          url: '/api/mdm/senior/status',
        },
        delete: {
          url: '/api/mdm/senior/status/{0}',
        }
      }
    }
  },
  sai: {
    osh: {
      proceedings: {
        insert: {
          url: "/api/sai/osh/proceedings"
        },
        update: {
          url: "/api/sai/osh/proceedings"
        },
        delete: {
          url: "/api/sai/osh/proceedings/{0}"
        }
      }
    },
    tbm: {
      insert: {
        url: "/api/sai/tbm/tbm",
        attendee: {
          url: "/api/sai/tbm/attendee"
        },
        equip: {
          url: "/api/sai/tbm/equip",
          result: {
            url: "/api/sai/tbm/equip/result"
          }
        },
      },
      update: {
        url: "/api/sai/tbm/tbm"
      },
      delete: {
        url: "/api/sai/tbm/tbm/{0}",
        attendee: {
          url: "/api/sai/tbm/attendee"
        },
        equip: {
          url: "/api/sai/tbm/equip",
        }
      },
      complete: {
        url: "/api/sai/tbm/tbm/complete",
      },
      preComplete: {
        url: "/api/sai/tbm/pre/complete",
      }
    },
    safetycheck: {
      item: {
        save: {
          url: "/api/sai/safetycheck/item"
        },
        delete: {
          url: "/api/sai/safetycheck/item"
        }
      },
      plan: {
        insert: {
          url: "/api/sai/safetycheck/plan"
        },
        update: {
          url: "/api/sai/safetycheck/plan"
        },
        delete: {
          url: "/api/sai/safetycheck/plan/{0}"
        },
        complete: {
          url: "/api/sai/safetycheck/plan/{0}"
        },
        end: {
          url: "/api/sai/safetycheck/status/end"
        },
      },
      detail: {
        insert: {
          url: "/api/sai/safetycheck/detail"
        },
        delete: {
          url: "/api/sai/safetycheck/detail"
        }
      },
      result: {
        newItem: {
          url: "/api/sai/safetycheck/result/new"
        }
      }
    },
    patrol: {
      resultItem: {
        insert: {
          url: "/api/sai/patrol/result/item",
          mst: "/api/sai/patrol/result/itemMst"
        },
        delete: {
          url: "/api/sai/patrol/result/item",
          mst: "/api/sai/patrol/result/itemMst"
        }
      },
      insert: {
        url: "/api/sai/patrol/patrol",
      },
      update: {
        url: "/api/sai/patrol/patrol",
      },
      delete: {
        url: "/api/sai/patrol/patrol/{0}",
      },
      complete: {
        url: "/api/sai/patrol/patrol/complete",
      },
      improve: {
        update: {
          url: "/api/sai/patrol/patrol/improve"
        },
        delete: {
          url: "/api/sai/patrol/patrol/improve",
        },
      },
    }
  },
  saas: {
    riskHazard: {
      update: {
        url: "/api/saas/base/riskhazard"
      },
    },
    review: {
      insert: {
        url: "/api/saas/assess/review"
      },
      update: {
        url: "/api/saas/assess/review"
      },
      complete: {
        url: "/api/saas/assess/review/complete"
      },
      delete: {
        url: "/api/saas/assess/review/{0}"
      },
    },
    assessPlan: {
      insert: {
        url: "/api/saas/assess/plan"
      },
      update: {
        url: "/api/saas/assess/plan"
      },
      complete: {
        url: "/api/saas/assess/plan/complete"
      },
      delete: {
        url: "/api/saas/assess/plan/{0}"
      },
      recheck: {
        url: "/api/saas/assess/plan/recheck"
      },
    },
    assessMap: {
      save: {
        url: "/api/saas/assess/map"
      }
    },
    scenario: {
      save: {
        url: "/api/saas/assess/scenario"
      },
      saveUnit: {
        url: "/api/saas/assess/scenario/unit"
      },
      delete: {
        url: "/api/saas/assess/scenario"
      }
    },
    action: {
      complete: {
        url:  "/api/saas/assess/action",
      }
    },
    targetVendor: {
      complete: {
        url:  "/api/saas/assess/targetvendor",
      }
    },
    meeting: {
      insert: {
        url: "/api/saas/assess/meeting"
      },
      update: {
        url: "/api/saas/assess/meeting"
      },
      delete: {
        url: "/api/saas/assess/meeting/{0}"
      },
    },
    resultStatus: {
      save: {
        url: "/api/saas/assess/result/status"
      },
      delete: {
        url: "/api/saas/assess/result/status/{0}"
      },
      deletes: {
        url: "/api/saas/assess/result/status"
      }
    }
  }
};

export default transactionConfig;
