/**
 * 조회용 config
 */
let selectConfig = {
  auth: {
    login: {
      refresh: {
        url: "/api/auth/refresh"
      },
      valid: {
        url: "/api/auth/valid"
      }
    },
    userInfo: {
      url: "/api/auth/user/info"
    }
  },
  system: {
    lang: {
      url: "/api/system/langs"
    }
  },
  ten: {
    company: {
      list: {
        url: "/api/ten/cmd/companies"
      },
      get: {
        url: "/api/ten/cmd/company"
      },
      check: {
        plant: {
          url: "/api/ten/cmd/check/plant"
        },
        user: {
          url: "/api/ten/cmd/check/user"
        },
        vendor: {
          url: "/api/ten/cmd/check/vendor"
        },
        filevolume: {
          url: "/api/ten/cmd/check/filevolume"
        },
      }
    },
    cmd: {
      gas: {
        url: "/api/ten/cmd/gasesMst"
      },
      checkitem: {
        url: "/api/ten/cmd/checkItemsMst"
      },
      item: {
        url: "/api/ten/cmd/itemsMst"
      },
    },
  },
  main: {
    impr: {
      url: "/api/main/impr/processState"
    },
    imprs: {
      url: "/api/main/imprs"
    },
    workPermit: {
      url: "/api/main/workPermit/map"
    },
    workPermitToday: {
      url: "/api/main/workPermit/today"
    },
    riskAssess: {
      url: "/api/main/processAssess"
    },
    vendorInfo: {
      url: "/api/main/vendor/infos"
    },
    tbmToday: {
      url: "/api/main/tbm/today"
    },
    list: {
      url: '/api/main/mobile/lists',
    }
  },
  com: {
    revision: {
      getversion: '/api/com/revision'
    },
    upload: {
      fileList: {
        url: "/api/com/uploadedFiles"
      },
      fileInfo: {
        url: "/api/com/uploadedFile"
      },
      preview: {
        url: "/api/com/imageToByte"
      },
      previewParamTask: {
        url: "/api/com/imageToByte/task"
      },
      fileDown: {
        url: "/api/com/fileToByte"
      },
    }
  },
  devtool: {
    dbtables: {
      url: "/api/devtool/generators"
    },
    dbtable: {
      url: "/api/devtool/generator/{0}/{1}"
    }
  },
  sas: {
    mst: {
      list: {
        url: "/api/sas/msts"
      },
    },
    give: {
      list: {
        url: "/api/sas/gives"
      },
      bydate: {
        url: "/api/sas/gives/date"
      },
    },
    report: {
      date: {
        url: "/api/sas/gives/report/date"
      },
      month: {
        url: "/api/sas/gives/report/month"
      },
    },
  },
  pjm: {
    info: {
      list: {
        url: "/api/pjm/infos"
      },
      get: {
        url: "/api/pjm/info/{0}"
      }
    },
    vendorUser: {
      list: {
        url: "/api/pjm/user/vendors"
      },
      inputList: {
        url: "/api/pjm/user/input/vendors"
      },
      self: {
        url: "/api/pjm/user/self/vendors"
      }
    },
    book: {
      get: {
        url: "/api/pjm/book/info"
      },
      print: {
        url: "/api/pjm/book/print/{0}"
      }
    },
    uninjury: {
      list: {
        url: "/api/pjm/uninjurys"
      },
      get: {
        url: "/api/pjm/uninjury"
      }
    },
    org: {
      list: {
        url: "/api/pjm/orgs"
      },
    },
    stackholder: {
      list: {
        url: "/api/pjm/stackholders/{0}"
      },
    },
    vendor: {
      list: {
        url: "/api/pjm/vendors"
      },
      users: {
        url: "/api/pjm/vendor/users"
      },
      process: {
        list: {
          url: "/api/pjm/vendor/process"
        },
        person: {
          url: "/api/pjm/vendor/process/person"
        },
      },
    },
    process: {
      list: {
        url: "/api/mdm/pim/project/process/{0}"
      },
    },
    hazard: {
      list: {
        url: "/api/mdm/sop/project/hazard/{0}"
      },
    },
    area: {
      list: {
        url: "/api/pjm/areas",
        plant: "/api/pjm/areas/plant",
        areaname: "/api/pjm/areas/plant/name"
      },
      process: {
        list: {
          url: "/api/mdm/pim/project/area/process"
        },
      },
    },
    activity: {
      list: {
        url: "/api/pjm/activity/msts"
      },
      check: {
        url: "/api/pjm/activity/mstcheck"
      },
      get: {
        url: "/api/pjm/activity/mst"
      },
      detail: {
        url: "/api/pjm/activity/activitys"
      }
    },
  },
  smf: {
    usePlan: {
      list: {
        url: "/api/smf/usePlans"
      },
      get: {
        url: "/api/smf/usePlan"
      },
      revs: {
        url: "/api/smf/usePlan/revs"
      },
      rev: {
        url: "/api/smf/usePlan/rev"
      },
    },
    monthlyPerformance: {
      list: {
        url: "/api/smf/monthlyPerformances"
      },
      get: {
        url: "/api/smf/monthlyPerformance"
      },
    },
    monthlyPerformanceVendor: {
      get: {
        url: "/api/smf/monthlyPerformanceVendor"
      },
    },
    statementItemUse: {
      vendor: {
        list: {
          url: "/api/smf/statementItemUse/vendors"
        },
        print: {
          url: "/api/smf/statementItemUse/print/vendor"
        },
      },
      list: {
        url: "/api/smf/statementItemUses"
      },
      print: {
        url: "/api/smf/statementItemUse/print"
      },
    },
  },
  psi: {
    diagramkind: {
      url: "/api/psi/diagramkinds"
    },
    diagram: {
      list: {
        url: "/api/psi/diagrams"
      },
      get: {
        url: "/api/psi/diagram/{0}"
      }
    },
    documentkind: {
      url: "/api/psi/documentkinds"
    },
    document: {
      list: {
        url: "/api/psi/documents"
      },
      get: {
        url: "/api/psi/document/{0}"
      }
    },
    hsl: {
      hazardmaterials: {
        url: "/api/psi/hsl/hazardmaterials"
      }
    },
    pfi: {
      power: {
        list: {
          url: "/api/psi/pfi/powers"
        },
        get: {
          url: "/api/psi/pfi/power/{0}"
        }
      },
      device: {
        list: {
          url: "/api/psi/pfi/devices"
        },
        get: {
          url: "/api/psi/pfi/device/{0}"
        },
        detail: {
          url: "/api/psi/pfi/device/{0}/{1}"
        }
      },
      pipegasket: {
        list: {
          url: "/api/psi/pfi/pipegaskets"
        },
        get: {
          url: "/api/psi/pfi/pipegasket/{0}"
        },
        substance: {
          list: {
            url: "/api/psi/pfi/pipegasket/substance/{0}"
          },
        },
      },
      valve: {
        list: {
          url: "/api/psi/pfi/valves"
        },
        get: {
          url: "/api/psi/pfi/valve/{0}"
        }
      },
    },
    pcd: {
      interlock: {
        list: {
          url: "/api/psi/pcd/interlocks"
        },
        get: {
          url: "/api/psi/pcd/interlock/{0}"
        }
      },
    },
    lbe: {
      fireproof: {
        list: {
          url: "/api/psi/lbe/fireproofs"
        },
        get: {
          url: "/api/psi/lbe/fireproof/{0}"
        }
      },
      fireequip: {
        list: {
          url: "/api/psi/lbe/fireequips"
        },
        get: {
          url: "/api/psi/lbe/fireequip/{0}"
        }
      },
      firedetect: {
        list: {
          url: "/api/psi/lbe/firedetects"
        },
        get: {
          url: "/api/psi/lbe/firedetect/{0}"
        }
      },
      gasalarm: {
        list: {
          url: "/api/psi/lbe/gasalarms"
        },
        get: {
          url: "/api/psi/lbe/gasalarm/{0}"
        }
      },
      localven: {
        list: {
          url: "/api/psi/lbe/localvens"
        },
        get: {
          url: "/api/psi/lbe/localven/{0}"
        }
      },
      wash: {
        list: {
          url: "/api/psi/lbe/washs"
        },
        get: {
          url: "/api/psi/lbe/wash/{0}"
        }
      },
      protect: {
        list: {
          url: "/api/psi/lbe/protects"
        },
        get: {
          url: "/api/psi/lbe/protect/{0}"
        },
        item: {
          list: {
            url: "/api/psi/lbe/protectitems/{0}"
          },
        }
      },
    }
  },
  sys: {
    appr: {
      type: {
        list: {
          url: "/api/sys/appr/types"
        },
        get: {
          url: "/api/sys/appr/type/{0}"
        }
      },
      line: {
        last: {
          url: "/api/sys/appr/lines/last"
        },
        list: {
          url: "/api/sys/appr/lines"
        },
      },
      list: {
        todo: {
          url: "/api/sys/appr/list/todo"
        },
        mycnt: {
          url: "/api/sys/appr/list/mycnt"
        },
      },
      info: {
        get: {
          url: "/api/sys/appr/info"
        },
      },
    },
    favorite: {
      list: {
        url: "/api/sys/favorites"
      },
    },
    menu: {
      list: {
        url: "/api/sys/men/menus"
      },
      get: {
        url: "/api/sys/men/menu/{0}"
      }
    },
    manual: {
      get: {
        url: "/api/sys/men/manual/{0}"
      }
    },
    userMenu: {
      list: {
        url: "/api/sys/auth/user/usermenus"
      },
      get: {
        url: "/api/sys/auth/user/usermenus/"
      },
      favorites: {
        url: "/api/sys/auth/user/favorite/menu/"
      }
    },
    code: {
      domain: {
        list: {
          url: "/api/sys/code/domains"
        }
      },
      group: {
        list: {
          url: "/api/sys/code/grps/{0}"
        }
      },
      usegroup: {
        list: {
          url: "/api/sys/code/usegrps"
        }
      },
      mst: {
        list: {
          url: "/api/sys/code/msts/{0}",
          use: {
            url: "/api/sys/code/msts/{0}/{1}"
          }
        },
      }
    },
    stepper: {
      grp: {
        list: {
          url: "/api/sys/stepper/grps"
        }
      },
      mst: {
        list: {
          url: "/api/sys/stepper/msts/{0}"
        },
        get: {
          url: "/api/sys/stepper/mst/{0}/{1}"
        },
      }
    },
    sequence: {
      list: {
        url: "/api/sys/sequences"
      },
      get: {
        url: "/api/sys/sequence/{0}"
      },
      check: {
        url: "/api/sys/sequence/check/{0}"
      }
    },
    attach: {
      list: {
        url: "/api/sys/attachsettings"
      },
      get: {
        url: "/api/sys/attachsetting/{0}"
      },
      check: {
        url: "/api/sys/attachsetting/check/{0}"
      }
    },
    excelUpload: {
      list: {
        url: "/api/sys/excelUploadSettings"
      },
      get: {
        url: "/api/sys/excelUploadSetting/{0}"
      },
      check: {
        url: "/api/sys/excelUploadSetting/check/{0}"
      },
      template: {
        url: "/api/sys/excelUpload/template"
      },
    },
    batch: {
      list: {
        url: "/api/sys/batchsettings"
      },
      get: {
        url: "/api/sys/batchsetting/{0}"
      },
      check: {
        url: "/api/sys/batchsetting/check/{0}"
      }
    },
    env: {
      list: {
        url: "/api/sys/envs"
      },
      get: {
        url: "/api/sys/env/{0}"
      },
    },
    notice: {
      list: {
        url: "/api/sys/notices"
      },
      main: {
        url: "/api/sys/main/notices"
      },
      get: {
        url: "/api/sys/notice/{0}"
      },
    },
    defect: {
      list: {
        url: "/api/sys/defects"
      },
      get: {
        url: "/api/sys/defect"
      },
    },
    lbl: {
      mst: {
        list: {
          url: "/api/sys/label/msts"
        }
      },
      dtl: {
        list: {
          url: "/api/sys/label/dtls/{0}"
        },
        get: {
          url: "/api/sys/label/dtl/{0}"
        },
      }
    },
    msg: {
      mst: {
        list: {
          url: "/api/sys/msg/msts"
        }
      },
      dtl: {
        list: {
          url: "/api/sys/msg/dtls/{0}"
        },
        get: {
          url: "/api/sys/msg/dtl/{0}"
        },
      }
    },
    auth: {
      grp: {
        list: {
          url: "/api/sys/auth/grps"
        },
        get: {
          url: "/api/sys/auth/grp/{0}"
        }
      },
      menu: {
        list: {
          url: "/api/sys/auth/menus"
        },
      },
      dept: {
        list: {
          url: "/api/sys/auth/depts"
        },
      },
      user: {
        list: {
          url: "/api/sys/auth/users"
        },
      },
      deptauth: {
        list: {
          url: "/api/sys/auth/deptauths"
        },
      },
      userauth: {
        list: {
          url: "/api/sys/auth/userauths"
        },
      },
      plant: {
        dept: {
          list: {
            url: "/api/sys/auth/plant/depts"
          },
        },
        user: {
          list: {
            url: "/api/sys/auth/plant/users"
          },
        },
      },
    },
  },
  ram: {
    safeHealSurvey: {
      list: {
        url: "/api/ram/safeHealSurvey"
      },
      print: {
        url: "/api/ram/safeHealSurvey/print"
      },
    },
    matrix: {
      list: {
        url: "/api/ram/base/matrixs",
        mst: "/api/ram/base/matrixsMst"
      },
      get: {
        url: "/api/ram/base/matrix/{0}",
        mst: "/api/ram/base/matrixMst/{0}"
      },
    },
    riskType: {
      list: {
        url: "/api/ram/base/riskTypes"
      },
      get: {
        url: "/api/ram/base/riskType/{0}"
      },
    },
    riskTypeCause: {
      list: {
        url: "/api/ram/base/riskType/causes"
      },
    },
    riskHazard: {
      list: {
        url: "/api/ram/base/riskHazards"
      },
      get: {
        url: "/api/ram/base/riskHazard/{0}"
      },
    },
    fmRiskHazard: {
      list: {
        url: "/api/ram/base/4mRiskHazards"
      },
      get: {
        url: "/api/ram/base/4mRiskHazard/{0}"
      },
    },
    assessPlan: {
      list: {
        url: "/api/ram/assess/plans"
      },
    },
    assessTeam: {
      list: {
        url: "/api/ram/assess/teams"
      },
    },
    assessConference: {
      list: {
        url: "/api/ram/assess/conferences"
      },
    },
    relatedDocument: {
      list: {
        url: "/api/ram/assess/relatedDocuments"
      },
      addData: {
        url: "/api/ram/assess/relatedDocument/addDatas"
      },
    },
    drawNode: {
      list: {
        url: "/api/ram/assess/drawNodes"
      },
    },
    assessSop: {
      list: {
        url: "/api/ram/assess/sopScenarios"
      },
    },
    kpsr: {
      get: {
        url: "/api/ram/kpsr/assess/plan/{0}"
      },
      print: {
        url: "/api/ram/kpsr/print/{0}"
      },
      targetProcess: {
        url: "/api/ram/kpsr/assess/target/processes/{0}"
      },
      scenario: {
        list: {
          url: "/api/ram/kpsr/scenarios"
        },
      },
      riskReduce: {
        list: {
          url: "/api/ram/kpsr/assess/riskReduce"
        },
      }
    },
    hazop: {
      get: {
        url: "/api/ram/hazop/assess/plan/{0}"
      },
      print: {
        url: "/api/ram/hazop/print/{0}"
      },
      targetProcess: {
        url: "/api/ram/hazop/assess/target/processes/{0}"
      },
      scenario: {
        list: {
          url: "/api/ram/hazop/scenarios"
        },
      },
      riskReduce: {
        list: {
          url: "/api/ram/hazop/assess/riskReduce"
        },
      }
    },
    checklist: {
      get: {
        url: "/api/ram/checklist/assess/plan/{0}"
      },
      targetProcess: {
        url: "/api/ram/checklist/assess/target/processes/{0}"
      },
      print: {
        url: "/api/ram/checklist/print/{0}"
      },
      scenario: {
        list: {
          url: "/api/ram/checklist/scenarios"
        },
      },
      riskReduce: {
        list: {
          url: "/api/ram/checklist/assess/riskReduce"
        },
      },
      master: {
        list: {
          url: "/api/ram/checklist/masters",
          pop : {
            url: "/api/ram/checklist/pop/masters",
          }
        },
      },
      target: {
        list: {
          url: "/api/ram/checklist/targets"
        },
        get: {
          url: "/api/ram/checklist/target/{0}"
        }
      }
    },
    jsa: {
      get: {
        url: "/api/ram/jsa/assess/plan/{0}"
      },
      print: {
        url: "/api/ram/jsa/print/{0}"
      },
      targetProcess: {
        url: "/api/ram/jsa/assess/target/processes/{0}"
      },
      scenario: {
        list: {
          url: "/api/ram/jsa/scenarios"
        },
      },
      riskReduce: {
        list: {
          url: "/api/ram/jsa/assess/riskReduce"
        },
      },
    },
    kras: {
      get: {
        url: "/api/ram/kras/assess/plan/{0}"
      },
      print: {
        url: "/api/ram/kras/print/{0}"
      },
      targetProcess: {
        url: "/api/ram/kras/assess/target/processes/{0}"
      },
      scenario: {
        list: {
          url: "/api/ram/kras/scenarios"
        },
      },
      riskReduce: {
        list: {
          url: "/api/ram/kras/assess/riskReduce"
        },
      }
    },
    fm: {
      get: {
        url: "/api/ram/4m/assess/plan/{0}"
      },
      print: {
        url: "/api/ram/4m/print/{0}"
      },
      targetProcess: {
        url: "/api/ram/4m/assess/target/processes/{0}"
      },
      scenario: {
        list: {
          url: "/api/ram/4m/scenarios"
        },
      },
      riskReduce: {
        list: {
          url: "/api/ram/4m/assess/riskReduce"
        },
      },
      riskRegister: {
        list: {
          url: "/api/ram/4m/assess/riskRegister"
        },
      },
    },
    riskRegister: {
      list: {
        url: "/api/ram/riskRegisters/assess"
      },
    },
  },
  sop: {
    iim: {
      accident: {
        near: {
          list: {
            url: "/api/sop/iim/accident/nears"
          },
          get: {
            url: "/api/sop/iim/accident/near/{0}"
          },
          prevention: {
            get: {
              url: "/api/sop/iim/accident/near/prevention/{0}",
            },
          },
        },
      }
    },
    work: {
      loc: {
        status: {
          url: "/api/sop/loc/work/dashboard"
        },
        list: {
          url: "/api/sop/loc/works"
        },
        get: {
          url: "/api/sop/loc/work"
        },
      }
    },
    lim: {
      lincense: {
        kind: {
          list: {
            url: "/api/sop/lim/licensekinds"
          },
        },
        request: {
          list: {
            url: "/api/sop/lim/license/requests"
          },
          get: {
            url: "/api/sop/lim/license/request/{0}"
          },
        },
        review: {
          list: {
            url: "/api/sop/lim/license/reviews"
          },
          get: {
            url: "/api/sop/lim/license/review/{0}"
          },
        },
        card: {
          list: {
            url: "/api/sop/lim/license/cards"
          },
          get: {
            url: "/api/sop/lim/license/card/{0}"
          },
        },
        equipment: {
          list: {
            url: "/api/sop/lim/license/equipment/{0}"
          },
        },
        material: {
          list: {
            url: "/api/sop/lim/license/material/{0}"
          },
        },
      },
    },
    ibm: {
      improve: {
        list: {
          url: "/api/sop/ibm/improvements"
        },
        get: {
          url: "/api/sop/ibm/improvement/{0}"
        },
        status: {
          list: {
            url: "/api/sop/ibm/improvement/status"
          },
          task: {
            url: "/api/sop/ibm/improvement/task/status"
          },
          requestDept: {
            url: "/api/sop/ibm/improvement/requestDept/status"
          },
        },
        print: {
          url: "/api/sop/ibm/improvement/print"
        },
      },
      immediate: {
        get: {
          url: "/api/sop/ibm/immediate/{0}"
        },
      },
    },
    psr: {
      checkItem: {
        list: {
          url: "/api/sop/psr/prestartup/checkItems"
        },
        get: {
          url: "/api/sop/psr/prestartup/checkItem/{0}"
        },
      },
      check: {
        list: {
          url: "/api/sop/psr/prestartupChecks"
        },
        get: {
          url: "/api/sop/psr/prestartupCheck/{0}"
        },
        checkTypeItemList: {
          url: "/api/sop/psr/prestartupCheck/checkType/items"
        },
        checklistPrint: {
          url: "/api/sop/psr/prestartup/checklistPrint/{0}"
        },
        checklistReportPrint: {
          url: "/api/sop/psr/prestartup/checklistReportPrint/{0}"
        },
      },
      checkItemImprove: {
        list: {
          url: "/api/sop/psr/prestartup/improves"
        },
      }
    },
    swp: {
      workChekItem: {
        list: {
          url: "/api/sop/swp/checkItems",
        },
      },
      gas: {
        list: {
          url: "/api/sop/swp/gases"
        },
      },
      map: {
        list: {
          url: "/api/sop/swp/maps"
        },
        recent: {
          url: "/api/sop/swp/map/recent"
        },
      },
      workPermit: {
        list: {
          url: "/api/sop/swp/workPermits"
        },
        get: {
          url: "/api/sop/swp/workPermit/{0}"
        },
        print: {
          url: "/api/sop/swp/workPermit/print/{0}/{1}"
        }
      },
    },
    min: {
      inspectitem: {
        list: {
          url: "/api/min/hhm/type/inspects"
        },
      },
      machinery: {
        list: {
          url: "/api/min/hhm/machinery/machineries"
        },
        get: {
          url: "/api/min/hhm/machinery/machinery/{0}",
          result: {
            url: "/api/min/hhm/machinery/result/{0}"
          }
        },
        check: {
          url: "/api/min/hhm/machinery/check"
        },
      },
      equipment: {
        annual: {
          list: {
            url: "/api/sop/min/equipment/annual"
          },
          get: {
            url: "/api/sop/min/equipment/annual/{0}/{1}"
          },
        },
        plan: {
          list: {
            url: "/api/sop/min/equipment/plans"
          },
          get: {
            url: "/api/sop/min/equipment/plan/{0}"
          }
        },
        result : {
          list: {
            url: "/api/sop/min/equipment/results"
          },
          item: {
            get: {
              url: "/api/sop/min/equipment/result/items/{0}/{1}"
            }
          }
        },
        checkCycle: {
          list: {
            url: "/api/sop/min/equipment/checkcycle"
          }
        },
        maintain: {
          list: {
            url: "/api/sop/min/equipment/maintain/maintains"
          },
          getYear: {
            url: "/api/sop/min/equipment/maintain/maintain/year/{0}"
          }
        },
        improve: {
          get: {
            url: "/api/sop/min/equipment/improve/{0}/{1}"
          }
        }
      },
      maintenance: {
        plan: {
          list: {
            url: "/api/sop/min/maintenance/plans"
          },
          get: {
            url: "/api/sop/min/maintenance/plan/{0}"
          }
        },
        result : {
          list: {
            url: "/api/sop/min/maintenance/results"
          },
        },
      },
    },
    hhm: {
      hazard: {
        check: {
          list: {
            url: "/api/sop/hhm/hazard/equipments"
          },
          get: {
            url: "/api/sop/hhm/hazard/equipment/{0}"
          },
          check: {
            url: "/api/sop/hhm/hazard/equipment/check"
          },
        },
        hazardHistory: {
          get: {
            url: "/api/sop/hhm/hazard/equipment/history/{0}"
          },
        },
        hazardImprove: {
          get: {
            url: "/api/sop/hhm/hazard/equipment/improve/{0}/{1}"
          },
        }
      },
    },
    edu: {
      course: {
        list: {
          url: "/api/sop/edu/course/infos"
        },
        get: {
          url: "/api/sop/edu/course/info/{0}"
        },
        check: {
          url: "/api/sop/edu/course/info/check"
        }
      },
      result: {
        list: {
          url: "/api/sop/edu/results"
        },
        get: {
          url: "/api/sop/edu/result/{0}",
          targetUser: {
            url: "/api/sop/edu/result/targetuser/{0}",
          },
          mobile: {
            url: "/api/sop/edu/result/mobile/{0}/{1}"
          }
        },
        print: {
          first: {
            url: "/api/sop/edu/result/print/first",
          },
          second: {
            url: "/api/sop/edu/result/print/second",
          },
          third: {
            url: "/api/sop/edu/result/print/third",
          },
        }
      },
      user: {
        list: {
          url: "/api/sop/edu/users"
        }
      },
    },
    eap: {
      base: {
        list: {
          url: "/api/sop/eap/eval/items",
          mst: "/api/sop/eap/eval/itemsMst"
        },
      },
      relation: {
        list: {
          url: "/api/sop/eap/eval/relations"
        }
      },
      planDocu: {
        list: {
          url: "/api/sop/eap/plan/docus",
          pop: {
           url: "/api/sop/eap/plan/pop/docus",
          }
        },
        get: {
          url: "/api/sop/eap/plan/docu/{0}"
        },
      },
      scenario: {
        list: {
          url: "/api/sop/eap/plan/scenarios"
        },
        get: {
          url: "/api/sop/eap/plan/scenario/{0}"
        },
        rev: {
          url: "/api/sop/eap/plan/scenario/revs/{0}",
          get: {
            url: "/api/sop/eap/plan/scenario/rev/{0}/{1}"
          }
        },
      },
      training: {
        plan: {
          list: {
            url: "/api/sop/eap/training/plans"
          },
          get: {
            url: "/api/sop/eap/training/plan/{0}"
          },
          getItem: {
            url: "/api/sop/eap/training/plan/items"
          },
        }
      }
    },
    opm: {
      folder: {
        list: {
          url: "/api/sop/opm/folders"
        },
        get: {
          url: "/api/sop/opm/folder/{0}"
        },
      },
      folderDetail: {
        list: {
          url: "/api/sop/opm/folder/details/{0}"
        },
        get: {
          url: "/api/sop/opm/folder/detail/{0}"
        },
        rev: {
          url: "/api/sop/opm/folder/detail/revs",
          get: {
            url: "/api/sop/opm/folder/detail/rev/{0}"
          }
        }
      },
      guidebook: {
        list: {
          url: "/api/sop/opm/guidebooks"
        },
        get: {
          url: "/api/sop/opm/guidebook/{0}"
        },
        rev: {
          url: "/api/sop/opm/guidebook/revs",
          get: {
            url: "/api/sop/opm/guidebook/rev/{0}"
          }
        }
      }
    },
    cto: {
      basic: {
        list: {
          url: "/api/sop/cto/infos"
        },
        get: {
          url: "/api/sop/cto/info/{0}"
        },
      },
      improve: {
        get: {
          url: "/api/sop/cto/info/improve/{0}"
        },
      },
      stdchecklist: {
        list: {
          url: "/api/sop/cto/std/checklists",
          mst: "/api/sop/cto/std/checklistsMst"
        },
      },
    }
  },
  mdm: {
    cmt: {
      list: {
        url: "/api/mdm/cmt/commutes"
      },
      userlist: {
        url: "/api/mdm/cmt/commute/userlists"
      },
    },
    cmd: {
      workCheckItem: {
        list: {
          url: "/api/mdm/cmd/checkItemsMst",
        }
      },
      gas: {
        list: {
          url: "/api/mdm/cmd/gasesMst",
        }
      },
      checkItem: {
        list: {
          url: "/api/mdm/cmd/itemsMst"
        }
      },
      codeMst: {
        list: {
          url: "/api/mdm/cmd/codeMsts/{0}",
        },
      },
      inspectitem: {
        list: {
          url: "/api/mdm/cmd/inspectitemMst",
        },
      }
    },
    cim: {
      vendor: {
        list: {
          url: "/api/mdm/cim/vendors"
        },
        get: {
          url: "/api/mdm/cim/vendor/{0}"
        },
        check: {
          url: "/api/mdm/cim/vendor/check/{0}"
        },
        checkportalid: {
          url: "/api/mdm/cim/vendor/checkportalid/{0}"
        },
        item: {
          list: {
            url: "/api/mdm/cim/vendoreval/items"
          },
        },
        input: {
          list: {
            url: "/api/mdm/cim/vendoreval/items"
          },
        },
        evaluation: {
          result: {
            list: {
              url: "/api/mdm/cim/vendoreval/results"
            },
            get: {
              url: "/api/mdm/cim/vendoreval/result/{0}"
            },
            item: {
              url: "/api/mdm/cim/vendoreval/resultitems"
            },
            user: {
              url: "/api/mdm/cim/vendoreval/resultusers"
            },
            history: {
              url: "/api/mdm/cim/vendoreval/resulthistory"
            },
          },
        }
      },
    },
    sop: {
      job: {
        list: {
          url: "/api/mdm/sop/sops"
        },
        get: {
          url: "/api/mdm/sop/sop/{0}"
        },
      },
      step: {
        url: "/api/mdm/sop/steps/{0}"
      },
      hazard: {
        url: "/api/mdm/sop/hazards/{0}/{1}"
      },
      jobstep: {
        url: "/api/mdm/sop/sopsteps"
      },
      jobhazard: {
        url: "/api/mdm/sop/sophazards"
      },
      jobhazardreason: {
        url: "/api/mdm/sop/sophazardreasons"
      },
      jobhazardrea: {
        url: "/api/mdm/sop/sophazardreason"
      },
      processHazard: {
        url: "/api/mdm/sop/sopprocesshazards"
      },
      processHazardReason: {
        url: "/api/mdm/sop/sopprocesshazardreasons"
      },
      sopHazard: {
        url: "/api/mdm/sop/processsophazard"
      },
      mm: {
        list: {
          url: "/api/mdm/sop/mms"
        },
      },
      chemsmst: {
        list: {
          url: "/api/mdm/sop/chemsmst"
        },
      },
      chems: {
        list: {
          url: "/api/mdm/sop/chems"
        },
      },
      checkitemmst: {
        list: {
          url: "/api/mdm/sop/checkitemsmst"
        },
      },
      checkitem: {
        list: {
          url: "/api/mdm/sop/checkitems"
        },
      },
    },
    soppro: {
      job: {
        list: {
          url: "/api/mdm/sop/sopspro" // getSopsPro
        },
      },
      jobstep: {
        url: "/api/mdm/sop/sopsteps"
      },
      jobhazard: {
        url: "/api/mdm/sop/sophazards"
      },
      jobhazardreason: {
        url: "/api/mdm/sop/sophazardreasonspro" //getSoplisthazardreasonPro
      },
      processHazard: {
        url: "/api/mdm/sop/sopprocesshazards"
      },
      processHazardReason: {
        url: "/api/mdm/sop/sopprocesshazardreasons"
      },
      sopHazard: {
        url: "/api/mdm/sop/processsophazard"
      },
    },
    user: {
      list: {
        url: "/api/mdm/hrm/users"
      },
      get: {
        url: "/api/mdm/hrm/user/{0}"
      },
      vendor: {
        list: {
          url: "/api/mdm/hrm/user/vendors"
        },
        self: {
          url: "/api/mdm/hrm/user/self/vendors"
        }
      },
      check: {
        loginid: {
          url: "/api/mdm/hrm/user/check/loginid"
        }
      },
    },
    dept: {
      list: {
        url: "/api/mdm/dim/depts"
      },
      get: {
        url: "/api/mdm/dim/dept/{0}"
      },
      check: {
        url: "/api/mdm/dim/dept/check/{0}"
      }
    },
    process: {
      list: {
        url: "/api/mdm/pim/processes"
      },
      listPop: {
        url: "/api/mdm/pim/project/processes"
      },
      get: {
        url: "/api/mdm/pim/process/{0}"
      },
      nameget: {
        url: "/api/mdm/pim/processnames"
      },
      check: {
        url: "/api/mdm/pim/process/check/{0}"
      }
    },
    construct: {
      list: {
        url: "/api/mdm/pim/constructs"
      },
      get: {
        url: "/api/mdm/pim/construct"
      },
    },
    equipment: {
      list: {
        url: "/api/mdm/fim/equipments"
      },
      get: {
        url: "/api/mdm/fim/equipment"
      },
      check: {
        url: "/api/mdm/fim/equipment/check/{0}"
      },
      spec: {
        list: {
          url: "/api/mdm/fim/equipment/specs"
        },
      },
      material: {
        list: {
          url: "/api/mdm/fim/equipment/materials"
        },
      },
      rank: {
        list: {
          url: "/api/mdm/fim/equipment/ranks"
        },
        get: {
          url: "/api/mdm/fim/equipment/rank"
        },
      },
      class: {
        list: {
          url: "/api/mdm/equipment/classess"
        },
        get: {
          url: "/api/mdm/equipment/class/{0}/{1}"
        },
        check: {
          url: "/api/mdm/equipment/class/check/{0}/{1}"
        },
        insItem: {
          list: {
            url: "/api/mdm/equipment/class/insitems/{0}/{1}"
          },
        },
        infoItem: {
          list: {
            url: "/api/mdm/equipment/class/infoitems"
          },
        },
        item: {
          list: {
            url: "/api/mdm/equipment/class/items/{0}/{1}"
          },
        },
      },
      psm: {
        list: {
          url: '/api/mdm/fim/equipment/psm',
        },
      },
    },
    senior: {
      list: {
        url: '/api/mdm/senior/seniors',
      },
      status: {
        list: {
          url: "/api/mdm/senior/statuses"
        },
        get: {
          url: "/api/mdm/senior/status/{0}"
        },
        rev: {
          url: "/api/mdm/senior/status/revs",
          get: {
            url: "/api/mdm/senior/status/rev/{0}"
          }
        }
      }
    }
  },
  sai: {
    hazard: {
      list: {
        url: "/api/sai/hazard/hazards"
      },
      get: {
        url: "/api/sai/hazard/hazard/{0}",
        dept: {
          url: "/api/sai/hazard/hazard/dept/{0}"
        }
      },
      check: {
        url: "/api/sai/hazard/hazard/check"
      },
      substance: {
        list: {
          url: "/api/sai/hazard/hazard/substances"
        }
      }
    },
    workmeas: {
      plan: {
        list: {
          url: "/api/sai/work/plans"
        },
        get: {
          url: "/api/sai/work/plan/{0}",
        },
        check: {
          url: "/api/sai/work/plan/check/{0}/{1}",
        },
      },
      result: {
        list: {
          url: "/api/sai/work/results"
        },
        get: {
          url: "/api/sai/work/result/{0}",
        },
        chart: {
          url: "/api/sai/work/result/chart"
        },
        factor: {
          url: "/api/sai/work/result/factor"
        }
      }
    },
    osh: {
      proceedings: {
        list: {
          url: "/api/sai/osh/proceedings"
        },
        get: {
          url: "/api/sai/osh/proceedings/{0}"
        }
      }
    },
    shm: {
      policy: {
        list: {
          url: "/api/sai/shm/policies"
        },
        get: {
          url: "/api/sai/shm/policy/{0}"
        }
      }
    },
    sob: {
      board: {
        list: {
          url: "/api/sai/sob/boards"
        },
        get: {
          url: "/api/sai/sob/board/{0}"
        }
      }
    },
    tbm: {
      list: {
        url: "/api/sai/tbm/tbms"
      },
      qrUrl: {
        url: "/api/sai/tbm/qr"
      },
      get: {
        url: "/api/sai/tbm/tbm/{0}",
        equip: {
          result: {
            url: "/api/sai/tbm/equip/result/{0}",
          }
        }
      },
      print: {
        url: "/api/sai/tbm/print/{0}/{1}"
      },
      respond: {
        url: "/api/sai/tbm/respond/{0}/{1}"
      }
    },
    safetycheck: {
      item: {
        list: {
          url: "/api/sai/safetycheck/items"
        }
      },
      status: {
        list: {
          url: "/api/sai/safetycheck/status"
        }
      },
      plan: {
        list: {
          url: "/api/sai/safetycheck/plans"
        },
        get: {
          url: "/api/sai/safetycheck/plan/{0}"
        },
        check: {
          url: "/api/sai/safetycheck/plan/check"
        }
      },
      detail: {
        get: {
          url: "/api/sai/safetycheck/detail"
        }
      },
      result: {
        newItem: {
          url: "/api/sai/safetycheck/result/new/{0}/{1}"
        },
        lunar: {
          url: "/api/sai/safetycheck/lunar/{0}"
        }
      },
      improve: {
        get: {
          url: "/api/sai/safetycheck/improve/{0}/{1}"
        }
      }
    },
    patrol: {
      resultItem: {
        list: {
          url: "/api/sai/patrol/result/items",
          mst: "/api/sai/patrol/result/itemsMst"
        }
      },
      list: {
        url: "/api/sai/patrol/patrols"
      },
      get: {
        url: "/api/sai/patrol/patrol/{0}",
      },
      improve: {
        get: {
          url: "/api/sai/patrol/patrol/improve/{0}"
        },
      },
    },
  },
  saas: {
    riskHazard: {
      list: {
        url: "/api/saas/base/riskhazards"
      },
      get: {
        url: "/api/saas/base/riskhazard/{0}"
      },
    },
    review: {
      list: {
        url: "/api/saas/assess/reviews"
      },
      get: {
        url: "/api/saas/assess/review/{0}"
      },
    },
    assessPlan: {
      list: {
        url: "/api/saas/assess/plans"
      },
      get: {
        url: "/api/saas/assess/plan/{0}"
      },
      summary: {
        url: "/api/saas/assess/summary"
      },
    },
    assessMap: {
      get: {
        url: "/api/saas/assess/map/{0}"
      }
    },
    targetVendor: {
      list: {
        url: "/api/saas/assess/targetvendor/{0}"
      },
      get: {
        url: "/api/saas/assess/targetvendor/get/{0}"
      }
    },
    scenario: {
      list: {
        url: "/api/saas/assess/scenarios"
      },
      get: {
        url: "/api/saas/assess/scenario/{0}"
      },
      other: {
        url: "/api/saas/assess/other/scenarios"
      },
      impr: {
        url: "/api/saas/assess/scenario/impr"
      },
    },
    action: {
      list: {
        url: "/api/saas/assess/actions"
      }
    },
    compare: {
      list: {
        url: "/api/saas/assess/compare"
      }
    },
    meeting: {
      get: {
        url: "/api/saas/assess/meeting/{0}"
      },
    },
    status: {
      list: {
        url: "/api/saas/assess/status/{0}"
      },
      print: {
        url: "/api/saas/assess/print"
      },
      statistics: {
        url: "/api/saas/assess/statistics"
      },
      riskLevel: {
        url: "/api/saas/assess/risklevels"
      },
      imprLevel: {
        url: "/api/saas/assess/imprlevels"
      },
      residual: {
        url: "/api/saas/assess/residual"
      },
    },
    resultStatus: {
      list: {
        url: "/api/saas/assess/result/status"
      },
      vendorlist: {
        url: "/api/saas/assess/result/status/vendor"
      }
    }
  }
};

export default selectConfig;
