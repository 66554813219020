<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div v-if="isUploaderSetting" class="fix-height row upload-cover">
    <div v-show="editable" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <q-uploader
        flat bordered with-credentials
        :ref="uploadRef"
        color="custom-orange"
        class="custom-upload"
        capture="environment"
        field-name="file" 
        :url="url"
        :headers="headers"
        method="POST"
        :label="convertLabel"
        :form-fields="formFields"
        :auto-upload="false"
        :hide-upload-btn="true"
        :multiple="uploaderSetting.multipleSelFlag === 'Y'"
        :accept="uploaderSetting.acceptExt"
        :max-file-size="uploaderSetting.limitSize * 1000000"
        :max-total-size="uploaderSetting.limitSize * 1000000 * uploaderSetting.limitCnt"
        :max-files="maxFiles"
        :no-thumbnails="uploaderSetting.previewFlag === 'Y'"
        :disable="!editable"
        :style="maxheight"
        @added="added"
        @finish ="finish"
        @failed="failed"
        @rejected="rejected"
      >
        <!-- :filter="filter" -->
        <template v-slot:header="scope">
          <div class="row no-wrap items-center q-pa-none q-gutter-xs">
            <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
            <div class="col" style="padding-top: 0px !important;margin-top: 0px !important;">
              <div class="q-uploader__title inline-block" style="padding: 4px 0px !important;font-size: 0.95em !important">{{convertLabel}}</div>&nbsp;&nbsp;
              <div class="q-uploader__subtitle inline-block" v-if="editable">{{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}</div>
            </div>
            <!-- <q-btn v-if="scope.queuedFiles.length > 0"
              icon="delete_outline"
              round dense flat
              size="sm"
              @click="scope.removeQueuedFiles">
              <q-tooltip>Clear All</q-tooltip>
            </q-btn> -->
            <!-- <q-btn v-if="scope.uploadedFiles.length > 0" -->
            <q-btn
              v-if="haveImage()"  
              icon="visibility"
              dense flat
              size="sm"
              @click="allImagePreView">
              <!-- 이미지 파일 미리보기 -->
              <q-tooltip>이미지 파일 미리보기</q-tooltip>
            </q-btn>
            <q-btn
              v-if="fileList&&fileList.length > 0"  
              icon="delete_outline"
              dense flat
              size="sm"
              @click="removeAllFiles">
              <!-- 파일 전체 삭제 -->
              <q-tooltip>파일 전체 삭제</q-tooltip>
            </q-btn>
            <!-- <q-btn v-if="scope.canAddFiles"
              round dense flat
              size="sm"
              icon="add_box" >
              <q-uploader-add-trigger />
              <q-tooltip>탐색창을 엽니다</q-tooltip>
            </q-btn> -->
            <!-- <q-btn v-if="scope.canUpload"
              round dense flat
              size="sm"
              icon="cloud_upload"
              @click="scope.upload">
              <q-tooltip>Upload Files</q-tooltip>
            </q-btn>

            <q-btn v-if="scope.isUploading"
              round dense flat
              size="sm"
              icon="clear"
              @click="scope.abort"  >
              <q-tooltip>Abort Upload</q-tooltip>
            </q-btn> -->
          </div>
        </template>
        <template v-slot:list="scope">
          <template v-if="scope.files && scope.files.length > 0">
            <q-list separator>
              <q-item v-for="file in scope.files" :key="file.name">
                <q-item-section>
                  <q-item-label class="full-width ellipsis">
                    {{ file.name }}
                  </q-item-label>

                  <q-item-label caption>
                    Status: {{ file.__status }}
                  </q-item-label>

                  <q-item-label caption>
                    {{ file.__sizeLabel }} / {{ file.__progressLabel }}
                  </q-item-label>
                </q-item-section>

                <q-item-section
                  v-if="file.__img"
                  thumbnail
                  class="gt-xs"
                >
                  <img :src="file.__img.src">
                </q-item-section>

                <q-item-section top side>
                  <q-btn
                    class="gt-xs"
                    size="12px"
                    flat
                    dense
                    round
                    icon="delete"
                    @click="scope.removeFile(file)"
                  />
                </q-item-section>
              </q-item>
            </q-list>
          </template>
          <template v-else>
            <div class="empty_dscription">
              <div style="text-align: center; padding-bottom:5px" v-show="editable">
                <q-chip square icon="info" style="width:100%">
                  Drag or Click
                </q-chip>
              </div>
              <div class="q-pl-md txtfileinfo" style="text-align: left">
                <!-- 업로드 가능 확장자 -->
                <span class="text-positive">업로드 가능 확장자</span>
                : {{uploaderSetting.acceptExt}}
                <br/>
                <!-- 업로드 가능 파일 수 -->
                <span class="text-positive">업로드 가능 파일 수</span>
                : {{uploaderSetting.limitCnt}}
                <br/>
                <!-- 허용 파일 크기 -->
                <span class="text-positive">허용 파일 크기</span>
                : {{uploaderSetting.limitSize}}MB
              </div>
              <q-uploader-add-trigger />
            </div>
          </template>
        </template>
      </q-uploader>
    </div>
    <div :class="fileListClass">
      <q-list separator bordered class="uploaded-file-list">
        <template v-if="fileList&&fileList.length > 0">
          <q-item class="fileuploadItem">
            <font style="font-size:medium;font-weight:600;">[{{convertLabel}}]</font>
          </q-item>
          <q-item v-for="(file, idx) in fileList" :key="idx" class="fileuploadItem">
            <q-item-section class="fileuploadColumn">    
              <q-item-label class="full-width file-origin-name" >
                <span class="text-blue cursor-pointer" @click.prevent="fileDown(file)" style="font-size: 0.95em !important">
                  {{ file.oriFileNm }}
                </span>
                <span class="text-caption">
                  ({{ getFileSizeTextByRound(file.fileSize) }})
                </span>
              </q-item-label>
              <q-item-label v-if="uploaderSetting.explainFlag==='Y'">
                <!-- 파일설명 -->
                <c-text
                  :editable="editable"
                  class="file-description"
                  name="description"
                  placeholder="파일설명"
                  v-model="file.description">
                </c-text>
              </q-item-label>
            </q-item-section>

            <q-item-section
              v-if="file.contentType&&file.contentType.indexOf('image') > -1"
              thumbnail
              class="gt-xs cursor-pointer"
            >
              <img class="thumnailUpload" :src="file.imgUrl ? file.imgUrl : file.filePath" @click.prevent="setPreview(file, 'single')">
            </q-item-section>

            <q-item-section side>
              <div>
                <!-- <q-btn
                  v-if="file.contentType&&file.contentType.indexOf('image') > -1"
                  class="gt-xs"
                  size="11px"
                  flat
                  dense
                  round
                  icon="visibility"
                  @click.prevent="setPreview(file, 'single')"
                >
                  <q-tooltip>이미지 미리보기</q-tooltip>
                </q-btn> -->
                <q-btn
                  v-if="editable"
                  class="gt-xs"
                  size="11px"
                  flat
                  dense
                  round
                  icon="delete"
                  @click.prevent="removeFile(file)"
                >
                <!-- 파일 삭제 -->
                  <q-tooltip>파일 삭제</q-tooltip>
                </q-btn>
              </div>
            </q-item-section>
          </q-item>
        </template>
        <template v-else>
          <q-item class="fileuploadItem nofileArea" :style="{ display: 'block' }">
            <template>
              <font style="font-size:medium;font-weight:600;">[{{convertLabel}}]</font>
            </template>
            <div >
              <!-- 업로드된 파일이 없습니다. -->
              업로드된 파일이 없습니다.
            </div>
          </q-item>
        </template>
      </q-list>
    </div>
    <div v-if="attachInfo.isRev" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <q-toolbar class="bg-primary text-white before-file-list-toolbar">
        <!-- 이전 버전 파일 리스트 -->
        <q-toolbar-title>이전 버전 파일 리스트</q-toolbar-title>
      </q-toolbar>
      <q-list separator bordered class="uploaded-file-list">
        <template v-if="beforeFileList&&beforeFileList.length > 0">
          <q-item v-for="(file, idx) in beforeFileList" :key="idx" class="fileuploadItem">
            <q-item-section class="fileuploadColumn">    
              <q-item-label class="full-width file-origin-name" >
                <span class="text-blue cursor-pointer" @click.prevent="fileDown(file)" style="font-size: 0.95em !important">
                  {{ file.oriFileNm }}
                </span>
                <span class="text-caption">
                  ({{ getFileSizeTextByRound(file.fileSize) }})
                </span>
              </q-item-label>
              <q-item-label v-if="uploaderSetting.explainFlag==='Y'">
                <c-text
                  :editable="false"
                  class="file-description"
                  name="description"
                  v-model="file.description">
                </c-text>
              </q-item-label>
            </q-item-section>

            <q-item-section
              v-if="file.contentType&&file.contentType.indexOf('image') > -1"
              thumbnail
              class="gt-xs cursor-pointer"
            >
              <img class="thumnailUpload" :src="file.imgUrl ? file.imgUrl : file.filePath" @click.prevent="setPreview(file, 'single')">
            </q-item-section>

            <q-item-section side>
              <div>
                <!-- 복사 -->
                <q-btn
                  v-if="editable"
                  class="gt-xs"
                  size="11px"
                  flat
                  dense
                  round
                  label="복사"
                  @click.prevent="copyFile(file)"
                >
                </q-btn>
              </div>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </div>
    <div v-if="isPhotoView && previewfix&&(previewfix.src || (previewfix.fileList && previewfix.fileList.length > 0))" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="row">
        <div v-for="(file, idx) in previewfix.fileList" :key="idx" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 fixPreview">
          <div class="fixPreviewB">
          {{file.description}}
          </div>
          <div class="fixPreviewH">
            <q-img :src="file.src" class="fixPreviewImg"></q-img>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 이미지 미리보기 -->
    <q-dialog v-if="preview&&(preview.src || (preview.fileList && preview.fileList.length > 0))" 
      v-model="preview.isShow">
      <q-card class="preview-image-card">
        <q-btn color="white" text-color="primary" @click="preview.isShow = false" round dense icon="close" class="preview-close-btn">
          <!-- 창닫기 -->
          <q-tooltip class="bg-white text-primary">창닫기</q-tooltip>
        </q-btn>
        <q-carousel
          v-if="preview.fileList && preview.fileList.length > 0"
          swipeable animated thumbnails infinite
          v-model="preview.slide"
          @transition="transitionImage"
        >
          <q-carousel-slide
            v-for="(file, idx) in preview.fileList"
            :key="idx"
            :name="file.sysAttachFileId" 
            :img-src="file.src"></q-carousel-slide>
        </q-carousel>
        <q-img v-else :src="preview.src"></q-img>
        <q-card-section>
          <q-btn
            fab
            color="primary"
            icon="save_alt"
            class="absolute"
            style="top: 0; right: 12px; transform: translateY(-50%);"
            @click.prevent="fileDown(preview)"
          ></q-btn>
          <div class="row no-wrap items-center">
            <div class="col text-h6">
              {{preview.oriFileNm}}
            </div>
            <div class="col-auto text-grey text-caption q-pt-md row no-wrap items-center">
              {{ getFileSizeTextByRound(preview.fileSize) }}
            </div>
          </div>
        </q-card-section>
        <q-separator v-if="preview.description"></q-separator>
        <q-card-section v-if="preview.description" class="q-pt-none">
          <div class="text-subtitle1">
            <q-icon name="info"></q-icon>  
            <!-- 파일 설명 -->
            파일 설명
          </div>
          <div class="text-caption text-grey">
            {{preview.description}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import backendConfig from '@/js/backendConfig.js';
import { getAccessToken } from '@/utils/auth';
import mixinCommon from './js/mixin-common'
import mixinUpload from './js/mixin-upload'
export default {
  /* attributes: name, components, props, data */
  name: 'c-upload',
  mixins: [mixinCommon, mixinUpload],
  props: {
    name: {
      type: String,
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    isPhotoView: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '첨부파일', // 첨부파일
    },
    maxheight: {
      type: String,
      default: 'max-height:300px;min-height:300px;width: 100%;',
    },
    attachInfo: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: '',
          taskKey: '',
          beforeTaskKey: '',
          isRev: false,
        }
      }
    },
    imageRestriction: {
      type: Object,
      default: function() {
        return { 
          width: 0,
          height: 0,
        }
      }
    },
  },
  data() {
    return {
      uploadRef: 'custom-upload',
      preview: {
        slide: 1,
        isShow: false,
        sysAttachFileId: '',
        src: '',
        oriFileNm: '',
        fileSize: 0,
        description: '',
        fileExt: '',
        fileList: [],
      },
      previewfix: {
        slide: 1,
        isShow: false,
        sysAttachFileId: '',
        src: '',
        oriFileNm: '',
        fileSize: 0,
        description: '',
        fileExt: '',
        fileList: [],
      },
      isUploaderSetting: false,
      uploaderSetting: {
        multipleSelFlag: 'N',
        previewFlag: 'N',
        acceptExt: '*',
        limitSize: 1048576,
        limitCnt: 5,
        noThumbnails: false,
        explainFlag: 'N',
        resizeWidth: 0,
        resizeQuality: 1
      },
      fileList: [],
      beforeFileList: [],
      uploderSettingUrl: '',
      uploadedFilesUrl: '',
      tempTaskKeyUpdateUrl: '',
      allDeleteUrl: '',
      deleteUrl: '',
      previewUrl: '',
      fileDownUrl: '',
      fileCopyUrl: '',
    };
  },
  computed: {
    url() {
      return backendConfig.getUrl(transactionConfig.com.upload.uploading.url);
    },
    headers() {
      return [
        { name: 'X-Authorization', value: getAccessToken(), }
      ];
    },
    formFields() {
      let data = [
        {
          name: 'regUserId',
          value: this.$store.getters.user.userId,
        },
        {
          name: 'companyCd',
          value: this.$store.getters.user.companyCd,
        },
        {
          name: 'chgUserId',
          value: this.$store.getters.user.userId,
        }
      ];
      if (this.attachInfo) {
        if (this.attachInfo.taskClassCd) {
          data.push({
            name: 'taskClassCd',
            value: this.attachInfo.taskClassCd,
          })
        }
        /**
         * 신규인 경우 taskKey가 없을 수 있음
         * 해당의 경우 api-server에서 유니크한 id를 생성하여 저장 후 반환한다.
         */
        if (this.attachInfo.taskKey) {
          data.push({
            name: 'taskKey',
            value: this.attachInfo.taskKey,
          })
        } else {
          let val = this.attachInfo.taskClassCd + '_' + uid();
          data.push({
            name: 'taskKey',
            value: val,
          })
          this.$set(this.attachInfo, 'taskKey', val)
        }
      }
      return data;
    },
    maxFiles() {
      let fileCnt = this.fileList ? this.fileList.length : 0;
      return this.uploaderSetting.limitCnt - fileCnt
    },
    fileListClass() {
      return this.editable && !this.attachInfo.isRev 
        ? 'col-xs-12 col-sm-12 col-md-8 col-lg-8' : 
          (this.editable && this.attachInfo.isRev 
            ? 'col-xs-12 col-sm-12 col-md-4 col-lg-4' : 
              'col-xs-12 col-sm-12 col-md-12 col-lg-12')
    }
  },
  watch: {
    'attachInfo.taskClassCd'() {
      this.setUploderSetting();
      this.getUploadedFiles();
    },
    'attachInfo.taskKey'() {
      this.getUploadedFiles();
    },
    'attachInfo.beforeTaskKey'() {
      this.getBeforeUploadedFiles();
    },
    'attachInfo.isSubmit'() {
      if (this.fileList && this.fileList.length > 0) {
        this.$_.forEach(this.fileList, file => {
          this.$set(file, 'taskKey', this.attachInfo.taskKey)
        })
      }
      // 첨부 저장하는 단계
      this.$http.url = this.tempTaskKeyUpdateUrl;
      this.$http.type = 'PUT';
      this.$http.param = this.fileList;
      this.$http.request((_result) => {
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.getUploadedFiles();
        }
      },);
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {},
  destroyed() {},
  updated() {
  },
  /* methods */
  methods: {
    init() {
      // url setting
      this.uploderSettingUrl = selectConfig.sys.attach.get.url;
      this.uploadedFilesUrl = selectConfig.com.upload.fileList.url
      this.tempTaskKeyUpdateUrl = transactionConfig.com.upload.save.url
      this.allDeleteUrl = transactionConfig.com.upload.allDelete.url
      this.deleteUrl = transactionConfig.com.upload.delete.url
      this.previewUrl = selectConfig.com.upload.preview.url
      this.fileDownUrl = selectConfig.com.upload.fileDown.url
      this.fileCopyUrl = transactionConfig.com.upload.copy.url
      
      this.setUploderSetting();
      this.getUploadedFiles();
      this.setCompressOptions();
    },
    getFileSizeTextByRound(_fileByteSize) {
      return this.$comm.bytesToSize(_fileByteSize);
    },
    finish() {
      this.$refs['custom-upload'].removeUploadedFiles();
      this.$refs['custom-upload'].reset();
      this.reset();
      this.getUploadedFiles();
    },
    added(files) {
      if (this.queuedFileInfo.isUpload) return;
      // this.$refs['custom-upload'].queuedFiles
      this.compressCheck(files, this.uploaderSetting);
    },
    failed(info) {
      var infojson = JSON.parse(info.xhr.response);
      var returnCode = infojson.returnCode;
      let message = '';
      if (returnCode == 'UPLOAD_OVER') {
        var diff = parseInt(infojson.nowUploadVol) - parseInt(infojson.maxUploadVol)
        message = '최대 저장공간 용량을 초과했습니다.\n\r시스템담당자에게 연락하시기 바랍니다.\n\r회사 최대저장공간 : ' + this.$comm.bytesToSize(parseInt(infojson.maxUploadVol)) + '\n\r최대저장공간 초과 : : ' + this.$comm.bytesToSize(diff);
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: message,
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (info && info.files && info.files.length > 0) {
          message = '파일 [';
          let idx = 0;
          this.$_.forEach(info.files, file => {
            message += '"' + file.name + (idx !== (info.files.length - 1) ? '", ' : '"] ')
            idx++;
          });
          // 업로드에 실패하였습니다.
          message += '업로드에 실패하였습니다.'
        }
        this.$q.notify({
          color: 'negative',
          html: true,
          message: message,
          multiLine: true,
          timeout: 5000
        })
      }
    },
    rejected(info) {
      if (!info || info.length === 0) {
        return;
      }
      let message = '';
      this.$_.forEach(info, reject => {
        // accept, max-file-size, max-total-size, filter, etc
        switch(reject.failedPropValidation) {
          case 'max-file-size': // 파일용량 초과
          case 'max-total-size': // 파일 전체 용량 초과
            // '첨부하신 "' + reject.file.name + '"의 용량이 지정된 용량보다 큽니다. (지정된 용량 : 10MB, 파일 용량 : ' + this.getFileSizeTextByRound(reject.file.size) + ')';
            message += '첨부하신 "' + reject.file.name + '"의 용량이 지정된 용량보다 큽니다. (지정된 용량 : 10MB, 파일 용량 : ' + this.getFileSizeTextByRound(reject.file.size) + ')'
            break;
          case 'max-files': // 업로드 갯수 초과
            // '첨부하신 "' + reject.file.name + '"이 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다. (지정된 업로드 갯수 : 1)';
            message = '첨부하신 "' + reject.file.name + '"이 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다. (지정된 업로드 갯수 : 1)'
            break;
          case 'accept': // 확장자 맞지않음
            // '첨부하신 "' + reject.file.name + '"의 확장자가 올바르지 않습니다. (업로드 가능한 확장자 : xlsx)';
            message = '첨부하신 "' + reject.file.name + '"의 확장자가 올바르지 않습니다. (업로드 가능한 확장자 : xlsx)'
            break;
          case 'filter': // filter 걸린경우
          // 해당 기능 사용하지 않음으로 다국어 처리하지 않음
            message = '첨부하신 이미지 "' + reject.file.name + '"의 사이즈가 올바르지 않습니다. (사이즈 : ' + this.imageRestriction.width + ' X ' + this.imageRestriction.height + ')';
            break;
          default:
            break;
        }
      })
      this.$q.notify({
        color: 'negative',
        html: true,
        message: message,
        multiLine: true,
        timeout: 5000
      })
    },
    setUploderSetting() {
      if (!this.attachInfo.taskClassCd) return
      this.$http.url = this.$format(this.uploderSettingUrl, this.attachInfo.taskClassCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data) {
          this.isUploaderSetting = true;
          this.$_.extend(this.uploaderSetting, _result.data)
        }
      },);
    },
    getUploadedFiles() {
      if (!this.attachInfo.taskClassCd || !this.attachInfo.taskKey) {
        this.$set(this.$data, 'fileList', [])
        return
      }
      this.$http.url = this.uploadedFilesUrl;
      this.$http.type = 'GET';
      this.$http.param = this.attachInfo;
      this.$http.request((_result) => {
        this.$set(this.$data, 'fileList', [])
        if (_result.data) {
          // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
          this.fileList = _result.data;
          if (this.fileList && this.fileList.length > 0) {
            this.$_.forEach(this.fileList, item => {
              let encstr = item.previewImage;
              let url = window.URL || window.webkitURL;
              let blob = this.$comm.base64ToBlob(encstr, 'image/jpeg');
              let imgUrl = url.createObjectURL(blob);
              item.imgUrl = imgUrl;
            })
          }
        }
        this.$emit('files', this.fileList)
        if (this.isPhotoView) {
          this.allImagePreViewFix()
        }
      },);
    },
    getBeforeUploadedFiles() {
      if (this.attachInfo.taskClassCd && this.attachInfo.beforeTaskKey) {
        this.$http.url = this.uploadedFilesUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          taskClassCd: this.attachInfo.taskClassCd,
          taskKey: this.attachInfo.beforeTaskKey,
        };
        this.$http.request((_result) => {
          this.$set(this.$data, 'beforeFileList', [])
          if (_result.data) {
            // 신규로 등록됨에 따라 임시로 만든 taskKey를 반환처리
            this.beforeFileList = _result.data;
            if (this.beforeFileList && this.beforeFileList.length > 0) {
              this.$_.forEach(this.beforeFileList, item => {
                let encstr = item.previewImage;
                let url = window.URL || window.webkitURL;
                let blob = this.$comm.base64ToBlob(encstr, 'image/jpeg');
                let imgUrl = url.createObjectURL(blob);
                item.imgUrl = imgUrl;
              })
            }
          }
          this.$emit('before-files', this.beforeFileList)
        },);
      } else {
        this.$set(this.$data, 'beforeFileList', [])
      }
    },
    fileDown(file) {
      let thisVue = this;
      let accept = this.$comm.getAccept(file.fileExt);
      this.$http.url = this.fileDownUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sysAttachFileId: file.sysAttachFileId,
      };
      this.$http.request(
        _result => {
          let url = window.URL || window.webkitURL;
          let link = document.createElement('a');
          let blob = thisVue.$comm.base64ToBlob(_result.data, accept);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.oriFileNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file.oriFileNm;
            link.click();
          }
        },
      );
    },
    haveImage() {
      if (this.fileList && this.fileList.length > 0) {
        let flag = false;
        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            flag = true;
            return false;
          }
        })
        return flag;
      } else {
        return false;
      }
    },
    setPreview(file, type) {
      if (!file) return;
      // 이미지인  경우
      if (file.sysAttachFileId) {
        if (type === 'single') {
          this.preview.fileList = [];
        }
        this.$http.url = this.previewUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request(
          _result => {
            this.preview.isShow = true;
            this.preview.sysAttachFileId = file.sysAttachFileId;
            if (type === 'single' ) {
              this.preview.src = 'data:' + file.contentType + ';base64,' + _result.data ;
            } else {
              let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: file.sysAttachFileId });
              this.$set(curFile, 'src', ('data:' + file.contentType + ';base64,' + _result.data))
            }
            this.preview.oriFileNm = file.oriFileNm;
            this.preview.fileSize = file.fileSize;
            this.preview.description = file.description;
            this.preview.fileExt = file.fileExt;
          },
          _error => {
          }
        );
      }
    },
    allImagePreView() {
      if (this.fileList && this.fileList.length > 0) {
        this.preview.fileList = [];
        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            this.$set(file, 'src', file.imgUrl)
            this.preview.fileList.push(file)
          }
        })
        let firstFile = this.preview.fileList[0]
        this.preview.slide = firstFile.sysAttachFileId;
        this.preview.isShow = true;
        this.setPreview(firstFile, 'multi');
      } else {
        this.preview.fileList = [];
      }
    },
    allImagePreViewFix() {
      this.previewfix.fileList = [];
      if (this.fileList && this.fileList.length > 0) {

        this.$_.forEach(this.fileList, file => {
          if (file.contentType&&file.contentType.indexOf('image') > -1) {
            this.$set(file, 'src', file.imgUrl)
            this.previewfix.fileList.push(file)
          }
        })
        let firstFile = this.fileList[0]
        this.previewfix.slide = firstFile.sysAttachFileId;
        this.previewfix.isShow = true;
        this.setPreviewFix(firstFile, 'multi');
      } else {
        this.previewfix.fileList = [];
      }
    },
    setPreviewFix(file, type) {
      if (!file) return;
      // 이미지인  경우
      if (file.sysAttachFileId) {
        if (type === 'single') {
          this.previewfix.fileList = [];
        }
        this.$http.url = this.previewUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          sysAttachFileId: file.sysAttachFileId,
        };
        this.$http.request(
          _result => {
            this.previewfix.isShow = true;
            this.previewfix.sysAttachFileId = file.sysAttachFileId;
            if (type === 'single' ) {
              this.preview.src = 'data:' + file.contentType + ';base64,' + _result.data ;
            } else {
              let curFile = this.$_.find(this.previewfix.fileList, { sysAttachFileId: file.sysAttachFileId });
              this.$set(curFile, 'src', ('data:' + file.contentType + ';base64,' + _result.data))
            }
            this.previewfix.oriFileNm = file.oriFileNm;
            this.previewfix.fileSize = file.fileSize;
            this.previewfix.description = file.description;
            this.previewfix.fileExt = file.fileExt;
          },
          _error => {
          }
        );
      }
    },
    transitionImage(val, oldVal) {
      let curFile = this.$_.find(this.preview.fileList, { sysAttachFileId: val });
      if (curFile) {
        this.setPreview(curFile, 'multi');
      }

      let preFile = this.$_.find(this.preview.fileList, { sysAttachFileId: oldVal });
      if (preFile) {
        this.$set(preFile, 'src', preFile.imgUrl)
      }
    },
    removeFile(file) {
      this.$http.url = this.$format(this.deleteUrl, file.sysAttachFileId);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: '삭제 완료', // 삭제 완료
            multiLine: true,
            timeout: 5000
          });
          this.getUploadedFiles();
        }
      },);
    },
    removeAllFiles() {
      this.$http.url = this.$format(this.allDeleteUrl, this.attachInfo.taskKey, this.attachInfo.taskClassCd);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.$q.notify({
            color: 'positive',
            html: true,
            message: '삭제 완료', // 삭제 완료
            multiLine: true,
            timeout: 5000
          });
          this.getUploadedFiles();
        }
      },);
    },
    removeAllFilesMethod() {
      this.$http.url = this.$format(this.allDeleteUrl, this.attachInfo.taskKey, this.attachInfo.taskClassCd);
      this.$http.type = 'DELETE';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          this.getUploadedFiles();
        }
      },);
    },
    copyFile(file) {
      /**
       * 개정 시 이전버전에 있는 파일정보를 복사하는 기능
       * 개정하는 경우에 사용
       */
      this.$http.url = this.fileCopyUrl;
      this.$http.type = 'POST';
      this.$http.param = {
        taskClassCd: this.attachInfo.taskClassCd,
        taskKey: this.attachInfo.taskKey,
        sysAttachFileId: file.sysAttachFileId,
      };
      this.$http.request((_result) => {
        this.$q.notify({
          color: 'positive',
          html: true,
          message: '복사 완료', // 복사 완료
          multiLine: true,
          timeout: 5000
        });
        this.getUploadedFiles();
      },);
    }
  },
};
</script>
<style lang="sass">
.custom-upload
  .q-btn
    margin-top: 0px !important
    margin-left: 0px !important
.custom-upload.q-uploader--disable
    min-height: 200px !important
.upload-cover
  margin-top: -20px !important
  height: 220px

.q-uploader--disable
  max-height: 36px !important
  min-height: 20px !important

.empty_dscription
  font-size: 14px
  color: #909399
  margin-bottom: 0 !important

.file-origin-name
  line-height: 2em!important

.file-description
  .q-field--with-bottom
    padding-bottom: 0px !important
  .q-field--dense .q-field__inner
    padding-bottom: 0px !important

.preview-image-card 
  min-width: 800px
  .q-carousel 
    height:750px !important
.txtfileinfo
  padding-left: 0px !important
  word-break: break-all
  font-size: 12px
.nofileArea
  height: 196px
  font-size: 0.95em !important

.before-file-list-toolbar
  min-height: 20px !important
  .q-toolbar__title
    font-size: 1em !important
.fixPreviewB
  font-size: 16px
  font-weight: 600
  text-align: center
  border: 1px solid #cccccc
  padding-top: 10px
  padding-bottom: 10px
  background: #f8f8f8
.preview-close-btn
  position: absolute
  right: 5px
  top: 5px
  z-index: 99
</style>