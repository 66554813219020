var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-btn-group",
    { attrs: { outline: "" } },
    [
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isUseFlag && _vm.approvalInfo.isEndFlag,
            expression: "!isUseFlag && approvalInfo.isEndFlag",
          },
        ],
        attrs: {
          label: _vm.approvalInfo.endFlagBtnLabel,
          icon: "check",
          color: "red-custom",
        },
        on: { btnClicked: _vm.btnEndEvent },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBtnApprRetrieve && _vm.editable && _vm.isUseFlag,
            expression: "isBtnApprRetrieve&&editable&&isUseFlag",
          },
        ],
        attrs: { label: "결재회수", icon: "refresh", color: "red" },
        on: { btnClicked: _vm.apprRetrieve },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBtnApprHis && _vm.editable && _vm.isUseFlag,
            expression: "isBtnApprHis&&editable&&isUseFlag",
          },
        ],
        attrs: { label: "결재이력", icon: "history" },
        on: { btnClicked: _vm.apprHistoryPop },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isBtnApprReReq && _vm.isUseFlag,
            expression: "isBtnApprReReq&&isUseFlag",
          },
        ],
        attrs: { label: "재상신", icon: "check", color: "purple" },
        on: { btnClicked: _vm.apprReturnSave },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.isBtnApprReq && _vm.approvalInfo.approvalUrl && _vm.isUseFlag,
            expression: "isBtnApprReq&&approvalInfo.approvalUrl&&isUseFlag",
          },
        ],
        attrs: {
          isSubmit: _vm.approvalInfo.isApproval,
          url: _vm.approvalInfo.approvalUrl,
          param: _vm.approvalInfo.param,
          mappingType: "PUT",
          label: "결재요청",
          icon: "check",
          color: "purple",
        },
        on: { beforeAction: _vm.beforeAction, btnCallback: _vm.btnCallback },
      }),
      _c("c-btn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.isBtnApprReq &&
              !_vm.approvalInfo.approvalUrl &&
              _vm.isUseFlag,
            expression: "isBtnApprReq&&!approvalInfo.approvalUrl&&isUseFlag",
          },
        ],
        attrs: { label: "결재요청", icon: "check", color: "purple" },
        on: { btnClicked: _vm.apprRequestPop },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupApprOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }