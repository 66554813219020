<!--
목적 : select 기반 select 컴포넌트(Not Autocomplete)
Detail :
  comboConfig.js 정보를 참고하여 컴포넌트 렌더링시 select 데이터 조회 해서 option 추가
 *
examples:
 *
-->
<template>
  <q-select
    multiple use-chips use-input
    :options="filterItems"
    :dense="dense"
    filled
    :clearable="clearable"
    :label="convertLabel"
    stack-label
    :rules="[myRule]"
    :disable="disabled || !editable"
    color="orange-7"
    :class="[stype=='tableselect' ? 'customMultiTableSelect' : 'customMultiSelect']"
    option-disable="disable"
    v-model="vValue"
    @filter="filterFn"
    @input="input"
    @clear="clear">
    <template v-if="label" v-slot:label>
      <div class="row items-center all-pointer-events">
        <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
        </b>
      </div>
    </template>
    <template v-slot:selected-item="scope">
      <q-chip
        :removable="scope.opt.disable ? false : true"
        filled dense
        @remove="scope.removeAtIndex(scope.index)"
        :tabindex="scope.tabindex"
        color="orange-6"
        text-color="white"
        class="q-ma-none customchip"
      >
        {{ scope.opt.label }}
      </q-chip>
    </template>
  </q-select>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-multi-select',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
    },
    stype: {
      type: String,
      default: 'select',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: {
      type: [Array, String],
      default: null,
    },
    label: {
      // 부모로 부터 받아온 라벨 정보
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      // select 종류: edit, search 두 가지가 있음, (등록화면은 '선택하세요'로 조회화면은 '전체로 표시)
      type: String,
      default: '',
    },
    // 부모로 부터 select option을 받아올 경우
    comboItems: {
      type: Array,
      default: () => [],
    },
    // comboItems의 여러 항목 중 화면에 text로 보여지는 정보를 가진 attribute 명(option 생성시 사용됨)
    itemText: {
      type: String,
      required: true,
    },
    // comboItems의 여러 항목 중 실제 값을 가져야 하는 attribute 명(option 생성시 사용됨)
    itemValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    codeGroupCd: {
      type: String,
      default: ''
    },
    codeAttrVal1: {
      type: String,
      default: ''
    },
    isObject: {
      type: Boolean,
      default: false,
    },
    valueText: {
      type: String,
      default: ''
    },
    valueKey: {
      type: String,
      default: ''
    },
    disabledItems: {
      type: Array,
    },
  },
  data() {
    return {
      items: [],
      filterItems: [],
      vValue: [],
      isArray: true,
    };
  },
  computed: {
    placeholder() {
      let returnText = '전체' // 전체
      if (this.type === 'edit') {
        returnText = '선택'; // 선택
      }
      return returnText;
    },
  },
  watch: {
    // TODO : 부모의 v-model 변경을 감시(예를 들면, db로부터 데이터를 조회 한 후 값을 바인딩 할 경우)
    value() {
      this.setValue();
    },
    // 부모로 부터 값을 비동기로 가져올 경우 처리
    comboItems: {
      handler: function () {
        this.makeSelectOptions(this.comboItems);
      },
      deep: true,
    },
    // TODO : 부모로 부터 값을 받아오는 경우, 상황에 따라 value 속성 값이 먼저 들어오고 comboItems의 값이 늦게 들어올 수 있으므로,
    // 실제 항목인 items가 변경되면 vValue값을 value값으로 재 설정 해줌
    items() {
      this.setValue();
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    if (this.comboItems && this.comboItems.length > 0) {
      this.makeSelectOptions(this.comboItems);
    } else if (this.codeGroupCd) {
      this.getItems();
    }
  },
  mounted() {},
  beforeDestroy() {},
  destroyed() {},
  beforeUpdate() {},
  updated() {},
  /* methods */
  methods: {
    input(value) {
      // TODO : 부모에게 변경여부 전달
      let emitData = [];
      if (value && value.length > 0) {
        this.$_.forEach(value, item => {
          /**
           * 다음 두 상황으로 value를 return 할수 있게 처리
           *
           * 1. value만 Array에 담기는 경우
           * 2. Object로 itemText, itemValue에 맞춰 Array에 담기는 경우
           */
          if (this.isObject) {
            // case 1
            let data = this.$_.find(this.items, { value: item.value })
            let pushData = {}
            pushData[this.valueText] = data.label
            pushData[this.valueKey] = data.value
            emitData.push(pushData);
          } else {
            // case 2
            emitData.push(item.value);
          }
        })
      }

      if (!this.isArray) {
        emitData = emitData.toString();
      }

      this.$emit('input', emitData);
      this.$emit('datachange', value);
    },
    getItems() {
      this.$comm.getComboItems(this.codeGroupCd).then(_result => {
        if (this.disabledItems && this.disabledItems.length > 0) {
          this.$_.forEach(_result, item => {
            if (this.$_.indexOf(this.disabledItems, item.code) > -1) {
              item.disable = true;
            }
          })
        }
        this.makeSelectOptions(_result);
        this.$emit('setCodeData')
      });
    },
    /**
     * 모델 items 에서 itemText와 itemValue prop를 이용해서, bootstrap select에 적합한 option을 생성
     */
    makeSelectOptions(items) {
      var options = [];
      if (!items || items.length === 0) return options;
      let tempComboITems = [];
      // 쓰기권환이 있으며 활성화 상태인 경우
      if (this.editable && !this.disabled) {
        // 사용여부가 Y인 것만 리스트에 표현한다.
        // default : 사용여부 상관없이 전체
        tempComboITems = this.$_.reject(items, { useFlag: 'N' });
      } else {
        tempComboITems = items;
      }
      // 추가속성으로 조회조건이 추가적으로있을경우 필터링
      if (this.codeAttrVal1) {
        tempComboITems = this.$_.filter(tempComboITems, {attrVal1 : this.codeAttrVal1 });
      }

      this.$_.forEach(tempComboITems, (_item) => {
        options.push({
          label: _item[this.itemText],
          value: _item[this.itemValue],
          disable: _item.disable ? true : false,
        });
      });
      this.items = options;
      this.filterItems = options;
    },
    setValue() {
      let tempValue = [];
      if (this.$_.isArray(this.value)) {
        tempValue = this.$_.clone(this.value);
      } else {
        // String type으로 구분자가 ,로 들어오는 경우는 value값만
        if (this.value !== '') {
          tempValue = this.$_.split(this.value, ',')
        }
        this.isArray = false;
      }
      if (this.items && this.items.length > 0 && tempValue && tempValue.length > 0) {
        let data = [];
        this.$_.forEach(tempValue, item => {
          /**
           * 다음 두 상황으로 v-model을 받을 수 있게 처리
           *
           * 1. value만 Array에 담기는 경우
           * 2. Object로 itemText, itemValue에 맞춰 Array에 담기는 경우
           */
          if (this.isObject) {
            // case 1
            data.push(this.$_.find(this.items, { value: item[this.valueKey] }));
          } else {
            // case 2
            data.push(this.$_.find(this.items, { value: item }));
          }
        });
        if (data) {
          this.vValue = data;
        } else {
          this.vValue = [];
        }
      } else {
        this.vValue = [];
      }
    },
    myRule(val) {
      return !this.required || (val && val.length > 0) || ''
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.filterItems = this.items

          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }
      update(() => {
        const needle = val.toLowerCase()
        this.filterItems = this.items.filter(v => {
          return v.label.toLowerCase().indexOf(needle) > -1
        })
      })
    },
    clear() {
      let emitData = [];
      if (!this.isArray) {
        emitData = '';
      }
      this.$emit('input', emitData);
      this.$emit('datachange', []);
    },
  },
};
</script>
<style lang="sass">
.customMultiSelect
  .q-field__label
    margin-left: 5px
  .q-chip
    margin-top: 2px !important
    margin-right: 3px !important
    .q-chip__content
      font-size: 0.85em !important
  .q-field__append
    .q-field__focusable-action
      font-size: 0.85rem !important
  .q-chip__icon--remove
    font-size: 0.85rem !important
  .q-field__native
    height: min-content
.customMultiSelect.q-field--dense .q-field__control, .customMultiSelect.q-field--dense .q-field__marginal
  min-height: 30px !important
  height: min-content !important
.customMultiSelect.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
  height: min-content !important
.customMultiSelect.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container
  height: min-content !important
  padding-bottom: 2px !important


.q-td .q-field--dense.customMultiTableSelect .q-field__inner
  padding: 0px !important

.customMultiTableSelect
  .q-field__label
    margin-left: 5px
  .q-field__native.row.items-center
    margin-top: -4px !important
  .q-chip
    margin-top: 2px !important
    margin-right: 3px !important
    .q-chip__content
      font-size: 0.85em !important
  .q-field__append
    .q-field__focusable-action
      font-size: 0.80rem !important
  .q-chip__icon--remove
    font-size: 0.85rem !important
  .q-field__native
    height: min-content
.customMultiTableSelect.q-field--dense .q-field__control, .customMultiTableSelect.q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 24px !important
.customMultiTableSelect.q-field--filled .q-field__control
  padding: 0px 0px 0px 0px
  height: min-content !important
.customMultiTableSelect.q-field--auto-height.q-field--dense.q-field--labeled .q-field__control-container
  height: min-content !important
  padding-bottom: 0px !important

.default-table tbody td.edittd-multiSelect
  padding: 4px 8px 0px 8px !important
</style>