<!--
  목적 : 검색조건 컴포넌트
  작성자 : kdh
  Detail :
  *
  examples:
  *
  -->
<template>
  <!-- 검색 -->
  <q-card flat bordered class="default-card searchArea">
    <div class="row searchArearow"
      v-on:mouseover="onMouseOver"
      v-on:mouseout="onMouseOut"
      v-on:keyup.enter="onEnterKey"
    >
      <!-- 추가 검색 또는 사용자 정의 검색 slot -->
      <slot name="search"></slot>
      <slot name="table-button"></slot>
    </div>
  </q-card>
</template>

<script>
export default {
  name: 'c-search-box',
  props: {
    enter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMouseOver: false,
    };
  },
  computed: {
    // searchboxStatus() {

    //   return this.$store.state.app.appmain.opened;
    // },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());

    if (this.enter) {
      window.addEventListener('keyup', this.onEnter);
    }

    this.init();
  },
  mounted() {},
  beforeDestory() {
    if (this.enter) window.removeEventListener('keyup', this.onEnter);
  },
  methods: {
    init() {
    },
    onEnterKey() {
      this.$emit('enter');
    },
    onEnter(event) {
      if (event.which === 13 && this.isMouseOver && this.enter) {
        this.$emit('enter');
      }
    },
    onMouseOver() {
      this.isMouseOver = true;
    },
    onMouseOut() {
      this.isMouseOver = false;
    },
  },
};
</script>

<style lang="sass">
.searchArea
  padding: 20px 15px 0px 15px !important
  margin-bottom: 1rem
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important
  border-radius: 0px !important
.searchArearow
  margin-top: 6px !important
  .q-btn-group
    position: absolute
    right: 20px
</style>
